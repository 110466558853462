<template>
  <div class="auth-page">
    <!-- Main Title -->
    <h1>
      {{ isForgotPassword
          ? 'Reset Password'
          : isLogin
              ? 'Log In'
              : 'Sign Up'
      }}
    </h1>

    <!-- Subtitle under 'Log In' (only show if not in forgot-password mode) -->
    <p v-if="isLogin && !isForgotPassword" class="subtitle">
      Enter your credentials to access your account & apply with ease!
    </p>

    <!-- ===== FORGOT PASSWORD MODE ===== -->
    <form
      v-if="isForgotPassword"
      @submit.prevent="handleForgotPassword"
      class="auth-form"
    >
      <div class="form-group">
        <label>Please enter your email to reset your password:</label>
        <input
          type="email"
          v-model="resetEmail"
          placeholder="your-email@example.com"
          required
        />
      </div>

      <!-- Error and success messages -->
      <div v-if="error" class="error-message">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        {{ error }}
      </div>
      <div v-if="successMessage" class="success-message">
        <i class="fa fa-check-circle" aria-hidden="true"></i>
        {{ successMessage }}
      </div>

      <!-- Submit button -->
      <button type="submit" :disabled="loading">
        <span v-if="loading">
          <span class="spinner"></span>
          Sending Reset Email...
        </span>
        <span v-else>
          Send Reset Link
        </span>
      </button>

      <!-- Back to Login -->
      <button
        class="inline-btn back-btn"
        type="button"
        @click="toggleForgotPassword(false)"
      >
        Back to Login
      </button>
    </form>

    <!-- ===== LOGIN / SIGN-UP FORM ===== -->
    <form
      v-else
      @submit.prevent="handleSubmit"
      class="auth-form"
    >
      <!-- Email -->
      <div class="form-group">
        <label>Email</label>
        <input
          type="email"
          v-model="email"
          placeholder="your-email@example.com"
          required
        />
      </div>

      <!-- Password & Forgot password in same row -->
      <div class="form-group password-row">
        <div class="label-and-forgot">
          <label>Password</label>
          <button
            v-if="isLogin"
            class="inline-btn forgot-link"
            type="button"
            @click="toggleForgotPassword(true)"
          >
            Forgot password?
          </button>
        </div>
        <div class="password-wrapper">
          <input
            :type="showPassword ? 'text' : 'password'"
            v-model="password"
            placeholder="Enter your password"
            required
          />
          <button
            type="button"
            class="toggle-pw-btn"
            @click="showPassword = !showPassword"
            :aria-label="showPassword ? 'Hide password' : 'Show password'"
          >
            <i v-if="showPassword" class="fa fa-eye-slash"></i>
            <i v-else class="fa fa-eye"></i>
          </button>
        </div>
      </div>

      <!-- Confirm Password (Sign-Up Only) -->
      <div v-if="!isLogin" class="form-group">
        <label>Confirm Password</label>
        <div class="password-wrapper">
          <input
            :type="showConfirmPassword ? 'text' : 'password'"
            v-model="confirmPassword"
            placeholder="Re-enter your password"
            required
          />
          <button
            type="button"
            class="toggle-pw-btn"
            @click="showConfirmPassword = !showConfirmPassword"
            :aria-label="showConfirmPassword ? 'Hide password' : 'Show password'"
          >
            <i v-if="showConfirmPassword" class="fa fa-eye-slash"></i>
            <i v-else class="fa fa-eye"></i>
          </button>
        </div>
      </div>

      <!-- Error & Success Messages -->
      <div v-if="error" class="error-message">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        {{ error }}
      </div>
      <div v-if="successMessage" class="success-message">
        <i class="fa fa-check-circle" aria-hidden="true"></i>
        {{ successMessage }}
      </div>

      <!-- Submit Button -->
      <button type="submit" :disabled="loading">
        <span v-if="loading">
          <span class="spinner"></span>
          {{ isLogin ? 'Logging in...' : 'Signing up...' }}
        </span>
        <span v-else>
          {{ isLogin ? 'Log In' : 'Sign Up' }}
        </span>
      </button>

      <!-- Toggle between Login & Sign-up -->
      <p v-if="!isForgotPassword" class="toggle-mode">
        <span v-if="isLogin">Need an account? </span>
        <span v-else>Already have an account? </span>
        <button class="inline-btn" @click="toggleMode">
          {{ isLogin ? 'Sign Up' : 'Log In' }}
        </button>
      </p>
    </form>

    <!-- Social logins + disclaimer, shown on the login screen only -->
    <div v-if="isLogin && !isForgotPassword" class="social-and-disclaimer">
      <!-- 
      <div class="divider-or">Or continue with</div>
      <div class="social-buttons">
        <button class="social-btn google-btn" type="button">
          Google
        </button>
        <button class="social-btn linkedin-btn" type="button">
          LinkedIn
        </button>
      </div>
      -->
      <p class="tos">
  By logging in, you agree to our 
  <router-link :to="{ name: 'TermsOfService' }">Terms of Service</router-link>
  and
  <a
    href="https://fylterjobs.com/privacy-policy"
    target="_blank"
    rel="noopener noreferrer"
  >
    Privacy Policy
  </a>.
</p>

    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { auth, db } from '@/firebase'
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
  sendPasswordResetEmail
} from 'firebase/auth'
import {
  doc, setDoc, getDoc, serverTimestamp
} from 'firebase/firestore'

const router = useRouter()

// Form / Mode State
const isLogin = ref(true)
const isForgotPassword = ref(false)

// Inputs
const email = ref('')
const password = ref('')
const confirmPassword = ref('')
const resetEmail = ref('')

// Toggles for password visibility
const showPassword = ref(false)
const showConfirmPassword = ref(false)

// UI / Feedback State
const error = ref('')
const successMessage = ref('')
const loading = ref(false)

// Switch between Login and Sign-Up
function toggleMode() {
  isLogin.value = !isLogin.value
  error.value = ''
  successMessage.value = ''
}

// Forgot Password Toggle
function toggleForgotPassword(show) {
  isForgotPassword.value = show
  error.value = ''
  successMessage.value = ''
  resetEmail.value = ''
}

// Friendly error messages
function getFriendlyErrorMessage(err) {
  switch (err.code) {
    case 'auth/user-not-found':
      return 'No account found for that email.'
    case 'auth/wrong-password':
      return 'Incorrect password. Please try again.'
    case 'auth/email-already-in-use':
      return 'That email is already in use.'
    case 'auth/invalid-email':
      return 'Please enter a valid email address.'
    case 'auth/weak-password':
      return 'Your password is too weak. Please use a stronger password.'
    case 'permission-denied':
      return 'Insufficient permissions. Please verify your email first.'
    default:
      return err.message || 'Something went wrong. Please try again.'
  }
}

// ===== Forgot Password Flow =====
async function handleForgotPassword() {
  error.value = ''
  successMessage.value = ''
  loading.value = true

  try {
    await sendPasswordResetEmail(auth, resetEmail.value)
    successMessage.value = 'We’ve sent a password reset email. Check your inbox!'
  } catch (err) {
    error.value = getFriendlyErrorMessage(err)
  } finally {
    loading.value = false
  }
}

// ===== MAIN AUTH HANDLER =====
async function handleSubmit() {
  error.value = ''
  successMessage.value = ''

  // Validate passwords if signing up
  if (!isLogin.value && password.value !== confirmPassword.value) {
    error.value = 'Passwords do not match.'
    return
  }

  loading.value = true
  try {
    if (isLogin.value) {
      // ============ LOG IN ============ //
      const userCredential = await signInWithEmailAndPassword(auth, email.value, password.value)
      const user = userCredential.user

      // Check email verification
      if (!user.emailVerified) {
        await signOut(auth)
        throw new Error('Please verify your email before logging in.')
      }

      // ---- FIRST LOGIN DOC CREATION ----
      await ensureUserDocsExist(user.uid, user.email)

      // Now route them to EditProfile (change as needed)
      router.push({ name: 'EditProfile' })

    } else {
      // ============ SIGN UP ============ //
      const userCredential = await createUserWithEmailAndPassword(auth, email.value, password.value)
      const user = userCredential.user

      // Send verification email
      await sendEmailVerification(user)

      successMessage.value = 'Your account was created! A verification email has been sent. Please verify before logging in.'
      // (No Firestore writes here; we handle that after they verify & log in.)
    }
  } catch (err) {
    console.error(err)
    error.value = getFriendlyErrorMessage(err)
  } finally {
    loading.value = false
  }
}

// ===== ENSURE USER DOCS EXIST AT FIRST LOGIN =====
async function ensureUserDocsExist(uid, userEmail) {
  // 1) Check the user doc in /users
  const userDocRef = doc(db, 'users', uid)
  const userDocSnap = await getDoc(userDocRef)

  if (!userDocSnap.exists()) {
    // Create default user doc
    await setDoc(userDocRef, {
      email: userEmail,
      createdAt: serverTimestamp(),
      lastActive: serverTimestamp(),
      profileComplete: false,
      signUpSource: 'website',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      location: '',
      referralCode: '',
      referralsCount: 0,
      resumeFilePath: '',
      resumeUrl: '',
      certifications: [],
      education: [],
      favoritedJobs: [],
      skills: []
    })
  }

  // 2) Check the doc in /userPreferences
  const prefDocRef = doc(db, 'userPreferences', uid)
  const prefDocSnap = await getDoc(prefDocRef)

  if (!prefDocSnap.exists()) {
    // Create default user preferences
    await setDoc(prefDocRef, {
      datePosted: 30,
      excludeCompanies: false,
      excludeTimeFrame: 0,
      experienceFilter: ['All'],
      industryFilter: ['All'],
      isRemote: false,
      jobType: 'All',
      latitudeRange: { max: 42.28, min: 41.13 },
      longitudeRange: { max: -86.98, min: -88.53 },
      maxWage: 74000,
      minScore: 0,
      minWage: 25000,
      prioritizedJobTitles: [],
      searchDistance: 40,
      sortBy: 'date'
    })
  }
}
</script>

<style scoped>
.auth-page {
  max-width: 400px;
  margin: 3rem auto;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2rem;
}

/* Main title */
.auth-page h1 {
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 700;
}

/* Subtitle (under "Log In") */
.subtitle {
  text-align: center;
  font-size: 0.95rem;
  color: #555;
  margin-bottom: 1.5rem;
}

/* Basic form structure */
.auth-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group label {
  font-weight: 600;
  margin-bottom: 0.25rem;
  display: block;
}
.form-group input {
  width: 100%;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Password row: label + "Forgot password?" in the same line */
.password-row .label-and-forgot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
}
.forgot-link {
  font-size: 0.85rem;
  text-decoration: underline;
  padding-right: 0;
}

/* Password toggle styling */
.password-wrapper {
  display: flex;
  align-items: center;
}
.password-wrapper input {
  flex: 1;
}
.toggle-pw-btn {
  margin-left: 0.5rem;
  background: transparent;
  border: none;
  color: #007bff;
  font-size: 1.1rem;
  cursor: pointer;
  transition: color 0.2s ease;
}
.toggle-pw-btn:hover {
  color: #0056b3;
}

/* Error & Success Messages */
.error-message,
.success-message {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
}
.error-message {
  color: red;
}
.success-message {
  color: green;
}
.error-message i {
  color: red;
}
.success-message i {
  color: green;
}

/* Buttons */
button {
  background-color: #fc7115;
  background-image: linear-gradient(to bottom right, #fc7115, #ff9040);
  border: none;
  color: #fff;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  transition: background-image 0.2s ease;
}
button:hover {
  background-image: linear-gradient(to bottom right, #e35d0a, #f9822f);
}
button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Inline button links */
.inline-btn {
  background: none;
  border: none;
  padding: 0;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.9rem;
}
.inline-btn:hover {
  color: #0056b3;
}
.back-btn {
  margin-top: 1rem;
}

/* Loading Spinner */
.spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
  border: 2px solid #ccc;
  border-top: 2px solid #fc7115;
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
}
@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Toggle mode message */
.toggle-mode {
  margin-top: 0.5rem;
  text-align: center;
  font-size: 0.9rem;
}

/* Social logins + disclaimer container */
.social-and-disclaimer {
  margin-top: 1.5rem;
}

/* "Or continue with" horizontal divider (commented out) */
/*.divider-or {
  text-align: center;
  margin: 1.5rem 0 1rem;
  font-size: 0.9rem;
  color: #999;
  position: relative;
}
.divider-or::before,
.divider-or::after {
  content: "";
  display: block;
  width: 40%;
  height: 1px;
  background: #ccc;
  position: absolute;
  top: 50%;
}
.divider-or::before {
  left: 0;
}
.divider-or::after {
  right: 0;
}*/

/* Social Buttons (commented out) */
/*.social-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 1rem;
}
.social-btn {
  background: #fff;
  color: #444;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.social-btn:hover {
  background-color: #f5f5f5;
}
.google-btn { }
.linkedin-btn { }
*/

/* Disclaimer at the bottom */
.tos {
  text-align: center;
  font-size: 0.75rem;
  color: #666;
  margin-top: 0.5rem;
}
.tos a {
  color: #007bff;
  text-decoration: underline;
}
.tos a:hover {
  color: #0056b3;
}
</style>
