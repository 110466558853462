<template>
    <div>
      <!-- We only show <AppDownload /> if we fail to detect iOS/Android -->
      <AppDownload v-if="showFallback" />
    </div>
  </template>
  
  <script>
  import AppDownload from '@/components/AppDownload.vue';
  
  export default {
    name: 'DownloadView',
    components: { AppDownload },
  
    data() {
      return {
        showFallback: false
      };
    },
  
    mounted() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      console.log('[DownloadView] userAgent:', userAgent);
  
      // iOS detection
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location.href =
          "https://apps.apple.com/us/app/fylter-jobs-job-search/id6581483279?ref=fylterjobs";
      }
      // Android detection
      else if (/android/i.test(userAgent)) {
        window.location.href =
          "https://play.google.com/store/apps/details?id=net.fylter.app";
      }
      // Fallback => show the AppDownload component
      else {
        this.showFallback = true;
        console.log('[DownloadView] Fallback triggered');
      }
    }
  };
  </script>
  
  <style scoped>
  /* Optional styling */
  </style>
  