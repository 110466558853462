<template>
    <div class="tos-container">
      <h1>Fylter Jobs Terms of Service</h1>
      <p class="last-updated"><strong>Last Updated:</strong> [03/09/2025]</p>
  
      <div class="toc">
        <h2>Table of Contents</h2>
        <ul>
          <li><a href="#eligibility">User Eligibility</a></li>
          <li><a href="#account">Account Registration & Responsibilities</a></li>
          <li><a href="#acceptable-use">Acceptable Use of the Platform</a></li>
          <li><a href="#content-privacy">Content and Privacy</a></li>
          <li><a href="#liability">Disclaimers and Limitation of Liability</a></li>
          <li><a href="#termination">Termination and Enforcement</a></li>
          <li><a href="#dispute-resolution">Governing Law and Dispute Resolution</a></li>
          <li><a href="#contact">Contact Information</a></li>
        </ul>
      </div>
  
      <section id="eligibility">
        <h2>User Eligibility</h2>
        <p>You must be at least 16 years old to use Fylter Jobs. Users under the age of 16 are not permitted to create an account or use the Platform. If you are 16 or 17, you must have a parent or legal guardian review and agree to these Terms on your behalf.</p>
        <p>By using Fylter Jobs, you affirm that you are legally permitted to work in the United States and are using the Platform to seek employment.</p>
        <p>The Platform is intended for U.S. users only and must be used in compliance with all applicable U.S. laws.</p>
      </section>
  
      <section id="account">
        <h2>Account Registration & Responsibilities</h2>
        <p>To access certain features of Fylter Jobs, you may need to create an account. You agree to:</p>
        <ul>
          <li>Provide truthful and accurate information.</li>
          <li>Create only one account under your real identity.</li>
          <li>Keep your login credentials secure and confidential.</li>
          <li>Not share or transfer your account.</li>
        </ul>
        <p>We reserve the right to disable, suspend, or terminate accounts for any reason, including violations of these Terms.</p>
      </section>
  
      <section id="acceptable-use">
        <h2>Acceptable Use of the Platform</h2>
        <p>You agree NOT to engage in any of the following prohibited activities:</p>
        <ul>
          <li>Copying, scraping, or extracting data from the Platform using bots, spiders, or other automated means.</li>
          <li>Transmitting spam, unsolicited messages, or advertisements.</li>
          <li>Uploading malware, viruses, or engaging in hacking attempts.</li>
          <li>Overloading our servers with excessive requests.</li>
          <li>Posting fraudulent, misleading, or unlawful content.</li>
          <li>Impersonating another person or misrepresenting your identity.</li>
        </ul>
        <p>Violations of this section may result in immediate account suspension or legal action.</p>
      </section>
  
      <section id="content-privacy">
        <h2>Content and Privacy</h2>
        <p>By submitting User Content (e.g., resumes, job applications) on the Platform, you grant Fylter Jobs a limited, non-exclusive, royalty-free license to use and distribute your content solely for the purpose of operating the Platform.</p>
        <p>We do not guarantee that employers will review, respond to, or retain your job applications. Once submitted, an employer controls how your data is handled. Please refer to their privacy policies before applying.</p>
        <p>Your use of Fylter Jobs is also subject to our <a href="/privacy-policy">Privacy Policy</a>, which explains how we collect, store, and use your personal data.</p>
      </section>
  
      <section id="liability">
        <h2>Disclaimers and Limitation of Liability</h2>
        <p>Fylter Jobs is provided "as is" without warranties of any kind. We do not guarantee:</p>
        <ul>
          <li>That the Platform will be uninterrupted, error-free, or secure.</li>
          <li>That job listings are accurate, current, or legitimate.</li>
          <li>That using the Platform will result in job offers or employment.</li>
        </ul>
        <p>To the fullest extent permitted by law, Fylter Jobs disclaims all liability for any loss, damages, or claims arising from your use of the Platform.</p>
        <p>Our total liability, if any, is limited to the amount you have paid to Fylter Jobs in the past six months.</p>
      </section>
  
      <section id="termination">
        <h2>Termination and Enforcement</h2>
        <p>We may suspend or terminate your access to the Platform at any time for any or no reason, including:</p>
        <ul>
          <li>Violation of these Terms.</li>
          <li>Engaging in fraudulent or harmful activity.</li>
          <li>Posting prohibited content.</li>
        </ul>
        <p>If your account is terminated, you may not create a new account without our permission.</p>
      </section>
  
      <section id="dispute-resolution">
        <h2>Governing Law and Dispute Resolution</h2>
        <p>These Terms are governed by the laws of [Insert State]. Any disputes shall be resolved through **binding arbitration** in [City, State], except for small claims or intellectual property matters, which may be resolved in a court of law.</p>
        <p>Class action lawsuits are not permitted under these Terms.</p>
        <p>You may opt out of arbitration by submitting written notice within 30 days of first agreeing to these Terms.</p>
      </section>
  
      <section id="contact">
        <h2>Contact Information</h2>
        <p>If you have questions about these Terms, you may contact us at:</p>
        <ul>
          <li><strong>Email:</strong> support@fylterjobs.com</li>
        </ul>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: "TermsOfService",
    mounted() {
      this.scrollToSection();
    },
    methods: {
      scrollToSection() {
        const hash = this.$route.hash;
        if (hash) {
          this.$nextTick(() => {
            const element = document.querySelector(hash);
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          });
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .tos-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  h1, h2 {
    color: #222;
  }
  
  .last-updated {
    font-size: 14px;
    color: #777;
  }
  
  .toc {
    background: #f8f8f8;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .toc ul {
    list-style-type: none;
    padding: 0;
  }
  
  .toc ul li {
    margin: 5px 0;
  }
  
  .toc ul li a {
    text-decoration: none;
    color: #007bff;
  }
  
  .toc ul li a:hover {
    text-decoration: underline;
  }
  
  section {
    margin-bottom: 40px;
  }
  
  ul {
    padding-left: 20px;
  }
  
  </style>
  