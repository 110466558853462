<template>
  <div class="apply-job">
    <!-- Hero Section -->
    <header v-if="job" class="hero">
      <div class="hero-content">
        <div class="hero-preheading">Apply For:</div>
        <h1 class="hero-title">{{ job.title }}</h1>
        <p class="hero-subtitle">
          <span class="hero-label">Company:</span> {{ job.company }}
        </p>
        <p class="hero-location">
          <span class="hero-label">Location:</span> {{ job.city }}, {{ job.state }}
        </p>
      </div>
    </header>

    <!-- Loading State -->
    <div v-if="loading" class="loading-state">
      Loading job details...
    </div>

    <!-- Error State -->
    <div v-else-if="error" class="error-state">
      {{ error }}
    </div>

    <!-- Main Content: Two-Column Layout + Application Form -->
    <div v-else class="apply-job-content">
      <!-- Two-Column Grid for Job Description & Job Details -->
      <div class="two-column-grid">
        <!-- Job Description in Left Column -->
        <section v-if="job" class="card job-description-section">
          <h2 class="section-title description-heading">Job Description</h2>
          <div class="job-description" v-html="formatDescription(job.description)"></div>
        </section>

        <!-- Job Details in Right Column -->
        <section v-if="job" class="card job-details-section">
          <h2 class="section-title">Job Details</h2>

          <!-- Remote vs. On-Site -->
          <p v-if="job.remote" class="detail-line">
            <strong>Location:</strong>
            This is a remote opportunity
            <span v-if="job.remoteStates && job.remoteStates.length">
              (open to candidates in {{ job.remoteStates.join(', ') }})
            </span>.
          </p>
          <p v-else class="detail-line">
            <strong>Location:</strong> {{ job.city }}, {{ job.state }}
          </p>

          <!-- Job Type -->
          <p v-if="job.type" class="detail-line">
            <strong>Type:</strong> {{ job.type }}
          </p>

          <!-- Salary Range -->
          <p
            v-if="job.salaryRange && job.salaryRange.min && job.salaryRange.max"
            class="detail-line"
          >
            <strong>Salary Range:</strong>
            ${{ job.salaryRange.min.toLocaleString() }} -
            ${{ job.salaryRange.max.toLocaleString() }}
          </p>

          <!-- Skills (as "chips") -->
          <div v-if="job.allSkills && job.allSkills.length" class="detail-line">
            <strong>Skills:</strong>
            <ul class="skills-list">
              <li
                v-for="(skill, idx) in job.allSkills.filter(s => s.toLowerCase() !== 'none')"
                :key="idx"
                class="skill-chip"
              >
                {{ skill }}
              </li>
            </ul>
          </div>
        </section>
      </div>

      <!-- Application Form Section -->
      <section v-if="job" class="card application-section">
        <h2 class="section-title">Submit Your Application</h2>

        <form @submit.prevent="submitApplication" class="application-form">
          <!-- Existing Resume (if user has one) -->
          <div v-if="existingResumeUrl" class="existing-resume-info">
            <p>
              <strong>Existing Resume on File:</strong>
              <a :href="existingResumeUrl" target="_blank">View</a>
            </p>
            <p>
              You can replace it by uploading a new file, or skip if you'd like to keep using your stored resume.
            </p>
          </div>

          <!-- Resume Upload -->
          <div class="form-field resume-upload">
            <label for="resumeInput" class="resume-label">
              Upload Resume
              <span class="required">*</span>
            </label>
            <div class="file-input-wrapper">
              <div class="custom-file-upload">
                <div class="file-upload-content">
                  <i class="fas fa-cloud-upload-alt upload-icon"></i>
                  <div class="upload-text">
                    <p>Drag and drop your resume here</p>
                    <p class="upload-subtext">or click to browse files</p>
                    <p class="file-types">Supported formats: PDF, DOCX</p>
                  </div>
                </div>
              </div>
              <input
                id="resumeInput"
                type="file"
                class="file-input"
                @change="handleFileUpload"
                accept=".pdf,.docx"
                :required="!existingResumeUrl && !resumeFile"
              />
            </div>
            <div v-if="resumeFile" class="file-info">
              <i class="fas fa-file-alt file-icon"></i>
              <span class="selected-filename">{{ resumeFile.name }}</span>
            </div>
            <button
              type="button"
              class="smart-fill-btn"
              @click.prevent="parseResume"
              :disabled="!resumeFile || parsing"
              title="Uses AI to auto-fill the application fields from your resume."
            >
              <i v-if="parsing" class="fas fa-spinner fa-spin"></i>
              <span v-else>
                <i class="fas fa-magic"></i>
                Auto Fill with AI
              </span>
            </button>
            <p class="button-description">
              Let AI help fill in the application fields from your resume.
            </p>
          </div>

          <!-- Common Required Fields -->
          <div class="form-field">
            <label>First Name<span class="required">*</span></label>
            <input v-model="applicant.firstName" required />
          </div>
          <div class="form-field">
            <label>Last Name<span class="required">*</span></label>
            <input v-model="applicant.lastName" required />
          </div>
          <div class="form-field">
            <label>Email<span class="required">*</span></label>
            <input type="email" v-model="applicant.email" required />
          </div>
          <div class="form-field">
            <label>Phone Number<span class="required">*</span></label>
            <input type="tel" v-model="applicant.phone" required />
          </div>

          <!-- DEI & Compliance fields (all required) -->
          <h3 class="section-subtitle">
            Additional Information <span class="required">*</span>
          </h3>
          <div class="form-field">
            <label>Gender <span class="required">*</span></label>
            <select v-model="applicant.dei.gender" required>
              <option disabled value="">Please select</option>
              <option>Male</option>
              <option>Female</option>
              <option>Non-binary</option>
              <option>I do not wish to answer</option>
            </select>
          </div>
          <div class="form-field">
            <label>Race <span class="required">*</span></label>
            <select v-model="applicant.dei.race" required>
              <option disabled value="">Please select</option>
              <option>White</option>
              <option>Black or African American</option>
              <option>Hispanic or Latino</option>
              <option>Asian</option>
              <option>Native American or Alaska Native</option>
              <option>Two or more races</option>
              <option>I do not wish to answer</option>
            </select>
          </div>
          <div class="form-field">
            <label>Veteran Status <span class="required">*</span></label>
            <select v-model="applicant.dei.veteranStatus" required>
              <option disabled value="">Please select</option>
              <option>Not a Veteran</option>
              <option>Veteran</option>
              <option>I do not wish to answer</option>
            </select>
          </div>
          <div class="form-field">
            <label>Are you disabled? <span class="required">*</span></label>
            <select v-model="applicant.dei.isDisabled" required>
              <option disabled value="">Please select</option>
              <option>No</option>
              <option>Yes</option>
              <option>I do not wish to answer</option>
            </select>
          </div>
          <div class="form-field">
            <label>Requires Sponsorship? <span class="required">*</span></label>
            <select v-model="applicant.dei.requiresSponsorship" required>
              <option disabled value="">Please select</option>
              <option>false</option>
              <option>true</option>
            </select>
          </div>
          <div class="form-field">
            <label>Authorized to Work? <span class="required">*</span></label>
            <select v-model="applicant.dei.isAuthorizedToWork" required>
              <option disabled value="">Please select</option>
              <option>false</option>
              <option>true</option>
            </select>
          </div>
          <div class="form-field">
            <label>At least 18? <span class="required">*</span></label>
            <select v-model="applicant.dei.isAtLeast18" required>
              <option disabled value="">Please select</option>
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
          </div>
          <div class="form-field">
            <label>At least 21? <span class="required">*</span></label>
            <select v-model="applicant.dei.isAtLeast21" required>
              <option disabled value="">Please select</option>
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
          </div>

          <!-- Additional Questions (unchanged from your snippet) -->
          <div
            v-for="question in questions"
            :key="question.id"
            class="form-field"
          >
            <label :for="question.id">
              {{ question.label }}
              <span v-if="question.required" class="required">*</span>
            </label>
            <template v-if="question.type === 'short-answer'">
              <input
                :id="question.id"
                v-model="applicant.additionalAnswers[question.id]"
                :required="question.required"
              />
            </template>
            <template v-else-if="question.type === 'long-answer'">
              <textarea
                :id="question.id"
                v-model="applicant.additionalAnswers[question.id]"
                :required="question.required"
              ></textarea>
            </template>
            <template v-else-if="question.type === 'select'">
              <div class="select-wrapper">
                <select
                  :id="question.id"
                  v-model="applicant.additionalAnswers[question.id]"
                  :required="question.required"
                >
                  <option value="" disabled selected>Select an option</option>
                  <option
                    v-for="option in question.options"
                    :key="option"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
            </template>
          </div>

          <button type="submit" class="cta-btn apply-btn">Submit Application</button>
        </form>
      </section>
    </div>

    <!-- Pop-up Modal for Resume Parse Success -->
    <transition name="fade">
      <div v-if="showParsePopup" class="modal-overlay" @click="closeParseModal">
        <div class="modal-content" @click.stop="">
          <h2>Resume Parsed</h2>
          <p>We have successfully parsed your resume. Relevant fields have been populated.</p>
          <button @click="closeParseModal">OK</button>
        </div>
      </div>
    </transition>

    <!-- Pop-up Modal for Application Submission Success -->
    <transition name="fade">
      <div
        v-if="showApplicationSuccessPopup"
        class="modal-overlay"
        @click="closeApplicationSuccessModal"
      >
        <div class="modal-content" @click.stop="">
          <h2>Application Submitted</h2>
          <p>Your application has been successfully submitted. Thank you!</p>
          <button @click="closeApplicationSuccessModal">OK</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
/* ---------------------------
   Imports & Reactive Refs
---------------------------- */
import { onMounted, ref} from 'vue'
import { useRoute } from 'vue-router'
import { db, storage, auth } from "@/firebase"
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore"
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage"
import { onAuthStateChanged } from 'firebase/auth'
import { marked } from "marked"
import { v4 as uuidv4 } from "uuid"

/* ---------------------------
   Reactive State
---------------------------- */
const route = useRoute()

const job = ref(null)
const jobDocId = ref(null)
const applicant = ref({
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  dei: {
    gender: "",
    race: "",
    veteranStatus: "",
    isDisabled: "",
    requiresSponsorship: "",
    isAuthorizedToWork: "",
    isAtLeast18: "",
    isAtLeast21: ""
  },
  additionalAnswers: {} // For custom questions
})

// If user has an existing resume in Firestore, store it here
const existingResumeUrl = ref(null)

const resumeFile = ref(null)
const loading = ref(true)
const error = ref(null)

// Additional questions array
const questions = ref([])

const parsing = ref(false)
const showParsePopup = ref(false)
const showApplicationSuccessPopup = ref(false)

/* ---------------------------
   Lifecycle
---------------------------- */
onMounted(async () => {
  jobDocId.value = route.params.docId
  if (!jobDocId.value) {
    error.value = "No job docId provided."
    loading.value = false
    return
  }

  console.log("Received job docId:", jobDocId.value)
  await fetchJob()
  await fetchQuestions()

  // If user is logged in, load user doc
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      await loadUserProfile(user.uid)
      await loadComplianceData(user.uid)
    }
  })
})

/* ---------------------------
   Methods & Handlers
---------------------------- */
async function fetchJob() {
  loading.value = true
  try {
    const jobDocRef = doc(db, "jobs", jobDocId.value)
    const snap = await getDoc(jobDocRef)
    if (snap.exists()) {
      job.value = { id: jobDocId.value, ...snap.data() }
      console.log("Fetched job data:", job.value)
    } else {
      error.value = "Job not found."
    }
  } catch (err) {
    error.value = "Error fetching job details."
    console.error("Error fetching job:", err)
  } finally {
    loading.value = false
  }
}

async function fetchQuestions() {
  if (!jobDocId.value) return
  try {
    const questionsRef = collection(db, "jobs", jobDocId.value, "questions")
    const snapshot = await getDocs(questionsRef)
    questions.value = snapshot.docs.map(d => ({ id: d.id, ...d.data() }))
  } catch (err) {
    console.error("Error fetching questions:", err)
  }
}

// Load base user doc
async function loadUserProfile(uid) {
  try {
    const userDocRef = doc(db, "users", uid)
    const snap = await getDoc(userDocRef)
    if (!snap.exists()) return

    const data = snap.data()
    if (data.firstName) applicant.value.firstName = data.firstName
    if (data.lastName) applicant.value.lastName = data.lastName
    if (data.email) applicant.value.email = data.email
    if (data.phoneNumber) applicant.value.phone = data.phoneNumber

    // If user doc has a stored resumeUrl
    if (data.resumeUrl) {
      existingResumeUrl.value = data.resumeUrl
    }
  } catch (err) {
    console.error("Error loading user doc:", err)
  }
}

// Load compliance subdoc
async function loadComplianceData(uid) {
  try {
    const compRef = doc(db, "users", uid, "compliance", "complianceData")
    const compSnap = await getDoc(compRef)
    if (compSnap.exists()) {
      const c = compSnap.data()
      applicant.value.dei.gender = c.gender || ""
      applicant.value.dei.race = c.race || ""
      applicant.value.dei.veteranStatus = c.veteranStatus || ""
      applicant.value.dei.isDisabled = c.isDisabled || ""
      applicant.value.dei.requiresSponsorship = c.requiresSponsorship ? "true" : "false"
      applicant.value.dei.isAuthorizedToWork = c.isAuthorizedToWork ? "true" : "false"
      applicant.value.dei.isAtLeast18 = c.isAtLeast18 ? "true" : "false"
      applicant.value.dei.isAtLeast21 = c.isAtLeast21 ? "true" : "false"
    }
  } catch (err) {
    console.error("Error loading compliance subdoc:", err)
  }
}

function handleFileUpload(event) {
  resumeFile.value = event.target.files[0]
  console.log("Resume file selected:", resumeFile.value)
}

async function parseResume() {
  if (!resumeFile.value) {
    alert("Please upload your resume before parsing.")
    return
  }

  parsing.value = true
  try {
    const formData = new FormData()
    formData.append("resume", resumeFile.value)
    // Could also pass question data if your parsing service needs it

    const response = await fetch(
      "https://resume-parsing-service-923505374158.us-central1.run.app/parse-resume",
      { method: "POST", body: formData }
    )
    if (!response.ok) {
      const errorText = await response.text()
      throw new Error("Resume parse error: " + errorText)
    }

    const data = await response.json()
    if (data.parsedData) {
      const parsed = data.parsedData
      if (parsed.firstName) applicant.value.firstName = parsed.firstName
      if (parsed.lastName) applicant.value.lastName = parsed.lastName
      if (parsed.email) applicant.value.email = parsed.email
      if (parsed.phone) applicant.value.phone = parsed.phone

      // If your AI also returns answers, incorporate them:
      if (parsed.answers && typeof parsed.answers === "object") {
        for (const [qid, ans] of Object.entries(parsed.answers)) {
          applicant.value.additionalAnswers[qid] = ans
        }
      }

      showParsePopup.value = true
    } else {
      console.warn("No parsedData returned from AI service.")
      alert("No data returned. Please try another resume.")
    }
  } catch (err) {
    console.error("Error parsing resume:", err)
    alert("Failed to parse resume. Please try again.")
  } finally {
    parsing.value = false
  }
}

async function submitApplication() {
  // Basic front-end validation
  if (
    !applicant.value.firstName.trim() ||
    !applicant.value.lastName.trim() ||
    !applicant.value.email.trim() ||
    !applicant.value.phone.trim() ||
    !applicant.value.dei.gender ||
    !applicant.value.dei.race ||
    !applicant.value.dei.veteranStatus ||
    !applicant.value.dei.isDisabled ||
    !applicant.value.dei.requiresSponsorship ||
    !applicant.value.dei.isAuthorizedToWork ||
    !applicant.value.dei.isAtLeast18 ||
    !applicant.value.dei.isAtLeast21
  ) {
    alert("Please fill in all required fields (including DEI).")
    return
  }

  // If no new file & no existing resume, we can't proceed
  if (!resumeFile.value && !existingResumeUrl.value) {
    alert("Please upload a resume.")
    return
  }

  loading.value = true
  try {
    const applicationId = uuidv4()
    const now = new Date()
    const jobId = job.value?.id || jobDocId.value
    if (!jobId) {
      alert("No valid job ID found.")
      loading.value = false
      return
    }

    // 1) If user selected a new resume, upload. Otherwise use existing
    let finalResumeUrl = existingResumeUrl.value
    if (resumeFile.value) {
      const storagePath = `unauthenticated_resumes/${jobId}/applicants/${applicationId}/${resumeFile.value.name}`
      const sRef = storageRef(storage, storagePath)
      await uploadBytes(sRef, resumeFile.value)
      finalResumeUrl = await getDownloadURL(sRef)
    }

    // 2) Add to subcollection: /jobs/{jobId}/applicants/{applicationId}
    const applicantsRef = collection(db, "jobs", jobId, "applicants")
    const appDocRef = doc(applicantsRef, applicationId)
    const applicationData = {
      applicationId,
      appliedOn: now,
      firstName: applicant.value.firstName,
      lastName: applicant.value.lastName,
      email: applicant.value.email,
      phone: applicant.value.phone,
      jobId,
      answers: applicant.value.additionalAnswers || {},
      resumeUrl: finalResumeUrl,
      dei: { ...applicant.value.dei }
    }
    await setDoc(appDocRef, applicationData)
    console.log("Application doc saved to job subcollection.")

    // 3) Also insert into appQueue
    const queueId = uuidv4()
    const queueRef = collection(db, "appQueue")
    const queueData = {
      assignedTo: null,
      complianceAnswers: {},
      createdAt: now,
      email: applicant.value.email,
      firstName: applicant.value.firstName,
      lastName: applicant.value.lastName,
      phoneNumber: applicant.value.phone,
      resumeUrl: finalResumeUrl,
      status: "pending",
      userId: auth.currentUser ? auth.currentUser.uid : null,
      webBasedApp: true,
      multiApplyFeature: false,
      jobUrl: job.value.url || "",
      jobId,
      location: `${job.value.city || ""},${job.value.state || ""}`,
      jobTitle: job.value.title || "",
      company: job.value.company || "",
      cpa: job.value.cpa ? String(job.value.cpa) : "",
      // DEI
      gender: applicant.value.dei.gender,
      race: applicant.value.dei.race,
      veteranStatus: applicant.value.dei.veteranStatus,
      isDisabled: applicant.value.dei.isDisabled,
      requiresSponsorship: applicant.value.dei.requiresSponsorship === "true",
      isAuthorizedToWork: applicant.value.dei.isAuthorizedToWork === "true",
      isAtLeast18: applicant.value.dei.isAtLeast18 === "true",
      isAtLeast21: applicant.value.dei.isAtLeast21 === "true"
    }
    await setDoc(doc(queueRef, queueId), queueData)
    console.log("Application also saved to appQueue.")

    // 4) Show success popup
    showApplicationSuccessPopup.value = true
    resetForm()
  } catch (err) {
    console.error("Error submitting application:", err)
    alert("An error occurred while submitting your application. Please try again.")
  } finally {
    loading.value = false
  }
}

function resetForm() {
  applicant.value = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    dei: {
      gender: "",
      race: "",
      veteranStatus: "",
      isDisabled: "",
      requiresSponsorship: "",
      isAuthorizedToWork: "",
      isAtLeast18: "",
      isAtLeast21: ""
    },
    additionalAnswers: {}
  }
  resumeFile.value = null
  // You can decide to keep existingResumeUrl if you want the user to see it again
  // existingResumeUrl.value = null
}

function formatDescription(description) {
  if (!description) return ""
  const processed = description
    .replace(/\\n\\n/g, "\n\n")
    .replace(/\\n/g, "\n")
    .replace(/\*\*/g, "**")
  marked.setOptions({ breaks: true, gfm: true })
  return marked(processed)
}

function closeParseModal() {
  showParsePopup.value = false
}
function closeApplicationSuccessModal() {
  showApplicationSuccessPopup.value = false
}
</script>

<style scoped>
/* -- The same styling from your original snippet, with minor additions for existingResumeUrl -- */

/* Global container */
.apply-job {
  text-align: center;
  margin: 0;
  background-color: #f3ede2;
  width: 100%;
}

/* Hero */
.hero {
  background-color: #f3ede2;
  text-align: left;
  color: #333;
  padding: 50px 0;
  width: 100%;
}
.hero-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1rem;
}
.hero-preheading {
  font-size: 1.1rem;
  margin-bottom: 5px;
  color: #666;
}
.hero-title {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 10px;
  color: #fc7115;
  line-height: 1.2;
  text-decoration: underline;
}
.hero-subtitle,
.hero-location {
  margin: 5px 0;
  color: #666;
  font-size: 1.1rem;
}
.hero-label {
  font-weight: bold;
}

/* Loading & error states */
.loading-state,
.error-state {
  text-align: center;
  font-size: 1.25rem;
  color: #555;
  padding: 20px;
}

/* Main content container */
.apply-job-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

/* Two columns */
.two-column-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 2rem;
}
@media (max-width: 768px) {
  .two-column-grid {
    grid-template-columns: 1fr;
  }
}

/* Card style */
.card {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: left;
}

/* Description heading */
.job-description-section {
  display: flex;
  flex-direction: column;
}
.description-heading {
  margin-top: 0;
  color: #fc7115;
}
.job-description {
  color: #555;
  line-height: 1.6;
  margin-top: 1rem;
}

/* Job details */
.job-details-section {
  display: flex;
  flex-direction: column;
}
.detail-line {
  margin-bottom: 0.75rem;
}
.detail-line strong {
  margin-right: 0.25rem;
}
.skills-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  list-style: none;
  padding: 0;
  margin: 0.5rem 0 0;
}
.skill-chip {
  background-color: #eee;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
}

/* Application form */
.application-section {
  background-color: #fff;
  margin-bottom: 2rem;
}
.section-title {
  color: #fc7115;
  font-size: 1.75rem;
  margin-top: 0;
  margin-bottom: 1rem;
}
.section-subtitle {
  font-size: 1.25rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #333;
}
.application-form {
  max-width: 600px;
  margin: 0 auto;
}
.form-field {
  margin-bottom: 20px;
  text-align: left;
}
.form-field label {
  display: block;
  font-weight: 600;
  margin-bottom: 5px;
}
.form-field input,
.form-field select,
.form-field textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Resume upload */
.resume-upload {
  background-color: #fafafa;
  margin-bottom: 25px;
  border: 1px dashed #ccc;
  border-radius: 8px;
  padding: 1rem;
}
.resume-label {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 12px;
  color: #333;
}
.file-input-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
}
.file-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
}
.custom-file-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #fff;
  min-height: 100px;
  transition: background-color 0.3s ease;
}
.custom-file-upload:hover {
  background-color: #fff5ef;
}
.file-upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.upload-icon {
  font-size: 24px;
  color: #fc7115;
  margin-bottom: 8px;
}
.upload-text {
  font-size: 1rem;
  color: #666;
  text-align: center;
}
.file-types {
  font-size: 0.9rem;
  color: #999;
}
.file-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.file-icon {
  color: #666;
}
.selected-filename {
  font-size: 0.95rem;
  color: #333;
}

/* AI parse button */
.smart-fill-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
}
.smart-fill-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
.button-description {
  font-size: 0.9rem;
  color: #666;
  margin-top: 5px;
}

/* Existing resume info */
.existing-resume-info {
  margin-bottom: 1rem;
  background: #f5f5f5;
  padding: 1rem;
  border-radius: 6px;
  color: #333;
}

/* Required mark */
.required {
  color: red;
  margin-left: 4px;
}

/* Submit button */
.cta-btn.apply-btn {
  background-color: #fc7115;
  color: #fff;
  padding: 15px 30px;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}
.cta-btn.apply-btn:hover {
  background-color: #e35d0a;
}

/* Modals & transitions */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.modal-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 2rem;
  max-width: 400px;
  width: 80%;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0,0,0,0.3);
}
.modal-content button {
  background-color: #fc7115;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 20px;
}
.modal-content button:hover {
  background-color: #e35d0a;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
