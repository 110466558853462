<template>
  <div class="jobswipe">
    <!-- App Download Promotion Section -->
    <AppDownload />

    <!-- Intro Banner in its own container -->
    <div class="intro-banner-container">
      <section class="intro-banner">
        <h1 class="intro-banner__main-title">Your Job Search Ends Here</h1>
        <h2 class="intro-banner__subtitle">Welcome to Fylter Jobs</h2>

        <!-- Three feature "cards" -->
        <div class="intro-cards">
          <div class="intro-card">
            <RocketIcon class="intro-card__icon" />
            <h3 class="intro-card__title">US-Based</h3>
            <p class="intro-card__text">
              Major refresh daily at 10:30–11:30 am CT, with hourly updates.
            </p>
          </div>

          <div class="intro-card">
            <ShieldIcon class="intro-card__icon" />
            <h3 class="intro-card__title">Ghost-Free</h3>
            <p class="intro-card__text">
              We avoid ghost jobs, giving your applications have the highest chance of success.
            </p>
          </div>

          <div class="intro-card">
            <ClockIcon class="intro-card__icon" />
            <h3 class="intro-card__title">Multi-Apply</h3>
            <p class="intro-card__text">
              Apply effortlessly and efficiently by utilizing our multi apply features.
            </p>
          </div>
        </div>

        <p class="intro-banner__tagline">
          Fewer listings, higher quality. Your path to meaningful employment starts here.
        </p>
      </section>
    </div>

    <!-- Search Section -->
    <section class="search-section-container">
      <div class="search-section">
        <form @submit.prevent="executeSearch" class="search-form">
          <h2 class="search-title">Find Your Next Opportunity</h2>

          <!-- Job Title Filter -->
          <div class="search-field">
            <label class="search-section-label">Job Title</label>
            <div class="search-input-wrapper">
              <input
                v-model="searchQueries.title"
                type="text"
                placeholder="Enter job title"
                class="search-input"
              />
              <span class="search-icon">
                <BriefcaseIcon />
              </span>
            </div>
          </div>

          <!-- Location Filter -->
          <div class="search-field">
            <label class="search-section-label">Location</label>
            <div class="search-input-wrapper">
              <LocationFilter
                class="search-input"
                @update:location="handleLocationUpdate"
                @error="handleLocationError"
              />
            </div>
          </div>

          <!-- Salary Range -->
          <div class="search-field">
            <label class="search-section-label">Salary Range</label>
            <div class="salary-range">
              <div class="search-field">
                <label class="search-label">Min Salary</label>
                <div class="search-input-wrapper">
                  <select v-model="filters.salaryMin" class="search-select">
                    <option value="30000">$30,000</option>
                    <option value="40000">$40,000</option>
                    <option value="50000">$50,000</option>
                    <option value="60000">$60,000</option>
                    <option value="70000">$70,000</option>
                    <option value="80000">$80,000</option>
                    <option value="100000">$100,000</option>
                    <option value="120000">$120,000</option>
                    <option value="140000">$140,000</option>
                    <option value="175000">$175,000</option>
                    <option value="200000">$200,000</option>
                  </select>
                  <span class="search-icon">
                    <DollarSignIcon />
                  </span>
                </div>
              </div>

              <div class="search-field">
                <label class="search-label">Max Salary</label>
                <div class="search-input-wrapper">
                  <select v-model="filters.salaryMax" class="search-select">
                    <option value="30000">$30,000</option>
                    <option value="40000">$40,000</option>
                    <option value="50000">$50,000</option>
                    <option value="60000">$60,000</option>
                    <option value="70000">$70,000</option>
                    <option value="80000">$80,000</option>
                    <option value="100000">$100,000</option>
                    <option value="120000">$120,000</option>
                    <option value="140000">$140,000</option>
                    <option value="175000">$175,000</option>
                    <option value="200000">$200,000</option>
                    <option value="400000">$200,000+</option>
                  </select>
                  <span class="search-icon">
                    <DollarSignIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- Job Type and Date Posted -->
          <div class="search-grid">
            <div class="search-field">
              <label class="search-section-label">Job Type</label>
              <div class="search-input-wrapper" style="position: relative;">
                <Multiselect
                  v-model="filters.jobTypes"
                  :options="jobTypeOptions"
                  multiple
                  placeholder="Select job types"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :hide-selected="false"
                  class="multiselect-custom"
                >
                  <template #header>
                    <div style="padding: 0.5rem;">
                      <label style="cursor: pointer;">
                        <input
                          type="checkbox"
                          :checked="areAllJobTypesSelected"
                          @change="toggleSelectAllJobTypes"
                        />
                        Select All
                      </label>
                      <button style="margin-left: 1rem;" @click.prevent="clearAllJobTypes">
                        Clear
                      </button>
                    </div>
                  </template>
                </Multiselect>
                <span class="search-icon">
                  <BriefcaseIcon />
                </span>
              </div>
            </div>

            <div class="search-field">
              <label class="search-section-label">Date Posted</label>
              <div class="search-input-wrapper">
                <select v-model="filters.datePosted" class="search-select">
                  <option value="1">Last 24 Hours</option>
                  <option value="3">Last 3 Days</option>
                  <option value="7">Last 7 Days</option>
                  <option value="14">Last 14 Days</option>
                  <option value="30">Last 30 Days</option>
                </select>
                <span class="search-icon">
                  <CalendarIcon />
                </span>
              </div>
            </div>
          </div>

          <!-- Industry & Experience -->
          <div class="search-grid">
            <div class="search-field">
              <label class="search-section-label">Industry</label>
              <div class="search-input-wrapper" style="position: relative;">
                <Multiselect
                  v-model="filters.industries"
                  :options="industryOptions"
                  multiple
                  placeholder="Select industries"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :hide-selected="false"
                  class="multiselect-custom"
                >
                  <template #header>
                    <div style="padding: 0.5rem;">
                      <label style="cursor: pointer;">
                        <input
                          type="checkbox"
                          :checked="areAllIndustriesSelected"
                          @change="toggleSelectAllIndustries"
                        />
                        Select All
                      </label>
                      <button style="margin-left: 1rem;" @click.prevent="clearAllIndustries">
                        Clear
                      </button>
                    </div>
                  </template>
                </Multiselect>
                <span class="search-icon">
                  <BriefcaseIcon />
                </span>
              </div>
            </div>

            <div class="search-field">
              <label class="search-section-label">Experience</label>
              <div class="search-input-wrapper" style="position: relative;">
                <Multiselect
                  v-model="filters.experiences"
                  :options="experienceOptions"
                  multiple
                  placeholder="Select experience"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :hide-selected="false"
                  class="multiselect-custom"
                >
                  <template #header>
                    <div style="padding: 0.5rem;">
                      <label style="cursor: pointer;">
                        <input
                          type="checkbox"
                          :checked="areAllExperiencesSelected"
                          @change="toggleSelectAllExperiences"
                        />
                        Select All
                      </label>
                      <button style="margin-left: 1rem;" @click.prevent="clearAllExperiences">
                        Clear
                      </button>
                    </div>
                  </template>
                </Multiselect>
                <span class="search-icon">
                  <BriefcaseIcon />
                </span>
              </div>
            </div>
          </div>

          <button
            type="submit"
            class="search-button"
            :disabled="loading"
          >
            {{ loading ? 'Searching...' : 'Search Jobs' }}
          </button>
        </form>
      </div>

      <div v-if="error" class="error-modal">
        <div class="error-modal__content">
          <p>{{ error }}</p>
          <button @click="closeErrorModal" class="error-modal__button">Okay</button>
        </div>
      </div>
    </section>

    <main class="jobswipe__main">
      <!-- Loading placeholder (initial load) -->
      <div v-if="loading && jobs.length === 0" class="jobs-loading">
        <div v-for="n in 3" :key="n" class="jobs-loading__card">
          <!-- skeleton blocks for loading -->
          <div class="jobs-loading__title"></div>
          <div class="jobs-loading__subtitle"></div>
          <div class="jobs-loading__text-short"></div>
          <div class="jobs-loading__text-long"></div>
        </div>
      </div>

      <!-- Error (if any) when no jobs are loaded yet -->
      <div v-else-if="error && jobs.length === 0" class="jobs-error">
        {{ error }}
      </div>

      <!-- If no matched jobs at all -->
      <div v-else-if="displayedJobs.length === 0 && displayedOtherJobs.length === 0">
        <div class="jobs-empty">
          No jobs found. Try adjusting your search criteria.
        </div>
      </div>

      <!-- Main content (matched & unmatched) -->
      <div v-else>
        <!-- Matched Jobs Section -->
        <div
          v-if="Object.keys(groupedMatchedJobs).length"
          class="matched-jobs-section"
        >
          <h3 style="margin-bottom:1rem;">Matched Jobs</h3>
          <p class="matched-jobs-summary" style="margin-bottom:1rem;">
  Loaded {{ jobs.length }} matched jobs
  <span v-if="searchQueries.title.trim()">for "<strong>{{ searchQueries.title }}</strong>"</span>
  <span v-if="locationFilter.isRemoteOnly">in remote listings</span>
  <span v-else>in {{ locationFilter.city }}, {{ locationFilter.state }}</span>.
  <span v-if="moreKeywordAvailable || moreDescriptionAvailable">
    Scroll to load more...
  </span>
</p>


          <!-- Grouped by company -->
          <div class="company-groups">
            <div
              v-for="(companyJobs, companyName) in groupedMatchedJobs"
              :key="companyName"
              class="company-group"
            >
              <h4>{{ companyName }}</h4>

              <!-- The currently visible job for this company (mini-carousel) -->
              <div v-if="companyJobs.length" class="carousel-job-card">
                <div
                  v-for="(job, idx) in companyJobs"
                  :key="job.docId"
                  v-show="idx === carouselIndex.matched[companyName]"
                  class="job-card"
                  @click="onCardClick(job)"
                  :class="{
                    'job-card--title': job.relevanceTier === 3,
                    'job-card--description': job.relevanceTier === 1
                  }"
                >
                  <div class="job-card__header">
                    <div>
                      <h2
                        class="job-card__title"
                        v-html="highlightTerm(job.title, searchQueries.title)"
                      ></h2>
                    </div>
                    <div class="job-card__actions">
                      <input
                        type="checkbox"
                        :value="job.docId"
                        v-model="selectedJobs"
                        class="job-select-checkbox"
                        :disabled="selectedJobs.length >= 10 && !selectedJobs.includes(job.docId)"
                      />
                    </div>
                  </div>

                  <!-- Tags -->
                  <div class="job-card__tags">
                    <span class="job-card__tag job-card__tag--location">
                      <MapPinIcon class="icon-class" />
                      {{ job.location }}
                    </span>
                    <span class="job-card__tag job-card__tag--salary">
                      ${{ job.salaryRange.min.toLocaleString() }} -
                      ${{ job.salaryRange.max.toLocaleString() }}
                    </span>
                    <span class="job-card__tag job-card__tag--type">
                      {{ job.type }}
                    </span>
                    <span
                      v-if="job.remote || job.globallyRemote"
                      class="job-card__tag job-card__tag--remote"
                    >
                      {{ job.globallyRemote ? 'Nationally Remote' : 'Remote' }}
                    </span>
                  </div>

                  <!-- Truncated Description -->
                  <div
                    class="job-card__description"
                    v-html="truncateDescription(job.description)"
                  ></div>

                  <!-- Skills -->
                  <div
                    class="job-card__skills"
                    v-if="job.allSkills && job.allSkills.some(s => s.toLowerCase() !== 'none')"
                  >
                    <p class="job-card__skills-title">Skills:</p>
                    <div class="job-card__skills-list">
                      <span
                        v-for="(skill, index) in job.allSkills
                          .filter(s => s.toLowerCase() !== 'none')
                          .slice(0, 4)"
                        :key="index"
                        class="job-card__skill-tag"
                      >
                        <strong>{{ skill }}</strong>
                      </span>
                    </div>
                  </div>

                  <!-- CTA on hover -->
                  <button
                    @click.stop="viewJobDetails(job)"
                    class="job-card__cta"
                  >
                    View Details
                  </button>
                </div>
              </div>

              <!-- Carousel controls -->
              <div class="carousel-controls" v-if="companyJobs.length > 1">
                <div class="dot-indicators">
                  <span
                    v-for="(dot, index) in getDots(companyJobs.length)"
                    :key="index"
                    class="dot"
                    :class="{ active: index === carouselIndex.matched[companyName] }"
                  ></span>
                </div>
                <button
                  class="carousel-next-button"
                  @click="nextJob('matched', companyName)"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Separator if both matched and unmatched exist -->
        <div
          v-if="Object.keys(groupedMatchedJobs).length &&
                 Object.keys(groupedUnmatchedJobs).length"
          style="margin:2rem 0;"
        >
          <hr />
        </div>

        <!-- Other Jobs Section -->
        <div v-if="Object.keys(groupedUnmatchedJobs).length">
          <h3>Other Jobs in Your Location</h3>
          <div class="company-groups" style="margin-top:1rem;">
            <div
              v-for="(companyJobs, companyName) in groupedUnmatchedJobs"
              :key="companyName"
              class="company-group"
            >
              <h4>{{ companyName }}</h4>

              <!-- Carousel container -->
              <div v-if="companyJobs.length" class="carousel-job-card">
                <div
                  v-for="(job, idx) in companyJobs"
                  :key="job.docId"
                  v-show="idx === carouselIndex.unmatched[companyName]"
                  class="job-card"
                  @click="onCardClick(job)"
                  :class="{
                    'job-card--title': job.relevanceTier === 3,
                    'job-card--description': job.relevanceTier === 1
                  }"
                >
                  <div class="job-card__header">
                    <div>
                      <h2
                        class="job-card__title"
                        v-html="highlightTerm(job.title, searchQueries.title)"
                      ></h2>
                    </div>
                    <div class="job-card__actions">
                      <input
                        type="checkbox"
                        :value="job.docId"
                        v-model="selectedJobs"
                        class="job-select-checkbox"
                        :disabled="selectedJobs.length >= 10 && !selectedJobs.includes(job.docId)"
                      />
                    </div>
                  </div>

                  <!-- Tags -->
                  <div class="job-card__tags">
                    <span class="job-card__tag job-card__tag--location">
                      <MapPinIcon class="icon-class" />
                      {{ job.location }}
                    </span>
                    <span class="job-card__tag job-card__tag--salary">
                      ${{ job.salaryRange.min.toLocaleString() }} -
                      ${{ job.salaryRange.max.toLocaleString() }}
                    </span>
                    <span class="job-card__tag job-card__tag--type">
                      {{ job.type }}
                    </span>
                    <span
                      v-if="job.remote || job.globallyRemote"
                      class="job-card__tag job-card__tag--remote"
                    >
                      {{ job.globallyRemote ? 'Nationally Remote' : 'Remote' }}
                    </span>
                  </div>

                  <!-- Truncated Description -->
                  <div
                    class="job-card__description"
                    v-html="truncateDescription(job.description)"
                  ></div>

                  <!-- Skills -->
                  <div
                    class="job-card__skills"
                    v-if="job.allSkills && job.allSkills.some(s => s.toLowerCase() !== 'none')"
                  >
                    <p class="job-card__skills-title">Skills:</p>
                    <div class="job-card__skills-list">
                      <span
                        v-for="(skill, index) in job.allSkills
                          .filter(s => s.toLowerCase() !== 'none')
                          .slice(0, 4)"
                        :key="index"
                        class="job-card__skill-tag"
                      >
                        <strong>{{ skill }}</strong>
                      </span>
                    </div>
                  </div>

                  <!-- CTA on hover -->
                  <button
                    @click.stop="viewJobDetails(job)"
                    class="job-card__cta"
                  >
                    View Details
                  </button>
                </div>
              </div>

              <!-- Controls: dot indicators and Next button -->
              <div class="carousel-controls" v-if="companyJobs.length > 1">
                <div class="dot-indicators">
                  <span
                    v-for="(dot, index) in getDots(companyJobs.length)"
                    :key="index"
                    class="dot"
                    :class="{ active: index === carouselIndex.unmatched[companyName] }"
                  ></span>
                </div>
                <button
                  class="carousel-next-button"
                  @click="nextJob('unmatched', companyName)"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Job Details Modal -->
        <div
          v-if="selectedJob"
          class="job-modal-overlay"
          @click="closeJobDetails"
        >
          <div
            class="job-modal__content"
            @click.stop
          >
            <div class="job-modal__header">
              <div>
                <h2 class="job-modal__title">{{ selectedJob.title }}</h2>
                <p class="job-modal__company">{{ selectedJob.company }}</p>
              </div>
              <button
                @click="closeJobDetails"
                class="job-modal__close"
              >
                <XIcon />
              </button>
            </div>

            <div class="job-modal__details">
              <div class="job-modal__info">
                <p>
                  <MapPinIcon class="icon-class" />
                  {{ selectedJob.location }}
                </p>
                <p>
                  <DollarSignIcon class="icon-class" />
                  ${{ selectedJob.salaryRange.min.toLocaleString() }} -
                  ${{ selectedJob.salaryRange.max.toLocaleString() }}
                </p>
                <p>
                  <BriefcaseIcon class="icon-class" />
                  {{ selectedJob.type }}
                </p>
                <p v-if="selectedJob.remote || selectedJob.globallyRemote">
                  <GlobeIcon class="icon-class" />
                  {{ selectedJob.globallyRemote ? 'Nationally Remote' : 'Remote' }}
                </p>
                <p>
                  <CalendarIcon class="icon-class" />
                  Posted {{ formatDate(selectedJob.datePosted) }}
                </p>
              </div>

              <div class="job-modal__section">
                <h3 class="job-modal__subtitle">Description</h3>
                <div
                  class="job-modal__description"
                  v-html="highlightTerm(formatDescription(selectedJob.description), searchQueries.title)"
                ></div>
              </div>

              <div class="job-modal__section">
                <h3 class="job-modal__subtitle">Preferred Skills</h3>
                <div class="job-modal__skills">
                  <span
                    v-for="(skill, index) in selectedJob.requiredSkills"
                    :key="index"
                    class="job-modal__skill-tag"
                  >
                    {{ skill.skill }}
                  </span>
                </div>
              </div>

              <div
                v-if="selectedJob.desiredSkills?.length"
                class="job-modal__section"
              >
                <h3 class="job-modal__subtitle">Desired Skills</h3>
                <div class="job-modal__skills">
                  <span
                    v-for="(skill, index) in selectedJob.desiredSkills"
                    :key="`desired-${index}`"
                    class="job-modal__skill-tag"
                  >
                    {{ skill.skill }}
                  </span>
                </div>
              </div>

              <div class="job-modal__actions job-modal__actions--split">
                <div class="job-modal__actions-left">
                  <button
                    v-if="selectedJob.url"
                    class="job-modal__company-site"
                    @click="applyOnCompanySite(selectedJob.url)"
                  >
                    Apply on Company Site
                  </button>
                </div>
                <div class="job-modal__actions-right">
                  <button
                    class="job-modal__apply"
                    @click="applyToJob(selectedJob.docId)"
                  >
                    Apply via Fylter
                  </button>
                  <button
                    class="job-modal__share"
                    @click="shareJob(selectedJob)"
                  >
                    <ShareIcon />
                    Share
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Infinite Scroll Sentinel -->
      <div
        v-show="(moreKeywordAvailable || moreDescriptionAvailable) && !loading"
        ref="infiniteScrollSentinel"
        style="height: 30px; background: transparent;"
      ></div>
      <!-- "No more results" message -->
<div
  v-if="!moreKeywordAvailable && !moreDescriptionAvailable && !loading"
  class="end-of-results-message"
>
  <hr />
  <p>
    All available jobs have been loaded. Consider adjusting search criteria. 
  </p>

  <p>
    Note: Our board is intentionally curated to remove ghost jobs, 
    so results may look lighter than on some other sites—but 
    you’re seeing the real thing.
  </p>
  <p>
    <strong>Stay tuned for our job alerts!</strong>
    We’ll soon let you sign up to be notified when new jobs 
    fitting your criteria drop.
  </p>
  <p>
    <strong>In the meantime:</strong>
    Check out our mobile apps! With the apps, you're able to save preset queries and load the current relevant jobs to you within seconds.
  </p>
</div>

    </main>

    <!-- Multi-Apply Footer -->
    <div v-if="selectedJobs.length > 0" class="multi-apply-footer">
      <p>Select more jobs to multi-apply (max 10)</p>
      <div>
        <button @click="applyToAllSelectedJobs">
          Apply to All
        </button>
        <button @click="clearSelections">
          Clear Selections
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  limit,
  startAfter,
  orderBy
} from "firebase/firestore";

import LocationFilter from '@/components/LocationFilter.vue';
import { marked } from 'marked';
import {
  MapPinIcon,
  BriefcaseIcon,
  DollarSignIcon,
  CalendarIcon,
  XIcon,
  GlobeIcon,
  ShareIcon,
  RocketIcon,
  ShieldIcon,
  ClockIcon
} from 'lucide-vue-next';
import AppDownload from '@/components/AppDownload.vue';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';

export default {
  name: 'HomePage',
  components: {
    LocationFilter,
    AppDownload,
    Multiselect,
    MapPinIcon,
    BriefcaseIcon,
    DollarSignIcon,
    CalendarIcon,
    XIcon,
    GlobeIcon,
    ShareIcon,
    RocketIcon,
    ShieldIcon,
    ClockIcon
  },

  data() {
    return {
      searchQueries: {
        title: ""
      },
      filters: {
        salaryMin: "50000",
        salaryMax: "100000",
        datePosted: "30",
        industries: [],
        jobTypes: [],
        experiences: []
      },
      industryOptions: [
        "Technology",
        "Healthcare",
        "Finance",
        "Retail",
        "Education",
        "Manufacturing",
        "Hospitality",
        "Government",
        "Construction",
        "Transportation",
        "Other"
      ],
      jobTypeOptions: [
        "Full-Time",
        "Part-Time",
        "Contract",
        "Internship"
      ],
      experienceOptions: [
        "Entry-Level",
        "Mid-Level",
        "Senior-Level"
      ],

      locationFilter: {
        city: "",
        state: "",
        coordinates: null,
        radius: 30,
        searchRange: null,
        isRemoteOnly: false
      },

      // Fetched jobs that match title/description queries
      jobs: [],
      // Additional "unmatched" jobs in location
      otherJobs: [],
      // What we display in the UI for matched/unmatched
      displayedJobs: [],
      displayedOtherJobs: [],

      loading: false,
      error: null,

      batchLimit: 30,
      keywordLastVisible: null,
      descriptionLastVisible: null,

      moreKeywordAvailable: true,
      moreDescriptionAvailable: true,

      selectedJob: null,
      showCopyPopup: false,

      selectedJobs: [],

      carouselIndex: {
        matched: {},
        unmatched: {}
      },

      // IntersectionObserver reference
      observer: null
    };
  },

  computed: {
    // For Industry
    areAllIndustriesSelected() {
      return this.filters.industries.length === this.industryOptions.length;
    },
    // For Job Types
    areAllJobTypesSelected() {
      return this.filters.jobTypes.length === this.jobTypeOptions.length;
    },
    // For Experiences
    areAllExperiencesSelected() {
      return this.filters.experiences.length === this.experienceOptions.length;
    },

    // Check location validity
    isLocationValid() {
      if (this.locationFilter.isRemoteOnly) return true;
      return (
        this.locationFilter.city &&
        this.locationFilter.state &&
        this.locationFilter.coordinates
      );
    },

    // Group matched jobs by company
    groupedMatchedJobs() {
      const groups = {};
      this.displayedJobs.forEach((job) => {
        if (!groups[job.company]) {
          groups[job.company] = [];
          if (this.carouselIndex.matched[job.company] === undefined) {
            this.carouselIndex.matched[job.company] = 0;
          }
        }
        groups[job.company].push(job);
      });
      return groups;
    },

    // Group unmatched by company
    groupedUnmatchedJobs() {
      const groups = {};
      this.displayedOtherJobs.forEach((job) => {
        if (!groups[job.company]) {
          groups[job.company] = [];
          if (this.carouselIndex.unmatched[job.company] === undefined) {
            this.carouselIndex.unmatched[job.company] = 0;
          }
        }
        groups[job.company].push(job);
      });
      return groups;
    }
  },

  mounted() {
    // Set up an intersection observer for infinite scroll
    this.initInfiniteScroll();
  },

  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  },

  methods: {
    closeErrorModal() {
      this.error = null;
    },

    handleLocationUpdate(locationData) {
      this.locationFilter = locationData;
    },
    handleLocationError(error) {
      this.error = error;
    },

    viewJobDetails(job) {
      this.selectedJob = job;
    },
    closeJobDetails() {
      this.selectedJob = null;
    },

    applyOnCompanySite(url) {
      if (url) {
        window.open(url, '_blank');
      }
    },
    applyToJob(docId) {
      if (!docId) return;
      const url = this.$router.resolve({ name: 'JobApply', params: { docId } }).href;
      window.open(url, '_blank');
    },

    shareJob(job) {
      const jobUrl = `${window.location.origin}/apply/${job.docId}`;
      navigator.clipboard.writeText(jobUrl)
        .then(() => {
          this.showCopyPopup = true;
          setTimeout(() => (this.showCopyPopup = false), 2000);
        })
        .catch((error) => {
          console.error("Failed to copy job URL:", error);
        });
    },

    truncateDescription(rawDescription, limit = 150) {
      if (!rawDescription) return '';
      const processedDescription = rawDescription
        .replace(/\\\\n/g, '\n')
        .replace(/\\n/g, '\n')
        .replace(/\*\*/g, '**');
      const htmlText = marked(processedDescription);
      let plainText = htmlText.replace(/<[^>]*>/g, '').trim();

      if (!plainText) return "No description provided.";
      if (plainText.length > limit) {
        plainText = plainText.slice(0, limit) + '...';
      }
      return this.highlightTerm(plainText, this.searchQueries.title);
    },

    formatDescription(description) {
      if (!description) return '';
      const processed = description
        .replace(/\\\\n/g, '\n')
        .replace(/\\n/g, '\n')
        .replace(/\*\*/g, '**');
      marked.setOptions({ breaks: true, gfm: true });
      return marked(processed);
    },

    highlightTerm(text, term) {
      if (!term || !text) return text;
      const escaped = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(`(${escaped})`, 'gi');
      return text.replace(regex, '<mark>$1</mark>');
    },

    computeMatchScore(job, searchWords) {
      let score = 0;
      if (Array.isArray(job.titleKeywords)) {
        searchWords.forEach(word => {
          if (job.titleKeywords.includes(word)) {
            score += 1;
          }
        });
      }
      return score;
    },

    // Toggle select all/clear for industries
    toggleSelectAllIndustries() {
      if (this.areAllIndustriesSelected) {
        this.filters.industries = [];
      } else {
        this.filters.industries = [...this.industryOptions];
      }
    },
    clearAllIndustries() {
      this.filters.industries = [];
    },

    // Toggle select all/clear for jobTypes
    toggleSelectAllJobTypes() {
      if (this.areAllJobTypesSelected) {
        this.filters.jobTypes = [];
      } else {
        this.filters.jobTypes = [...this.jobTypeOptions];
      }
    },
    clearAllJobTypes() {
      this.filters.jobTypes = [];
    },

    // Toggle select all/clear for experiences
    toggleSelectAllExperiences() {
      if (this.areAllExperiencesSelected) {
        this.filters.experiences = [];
      } else {
        this.filters.experiences = [...this.experienceOptions];
      }
    },
    clearAllExperiences() {
      this.filters.experiences = [];
    },

    // Initialize intersection observer
    initInfiniteScroll() {
      const sentinel = this.$refs.infiniteScrollSentinel;
      if (!sentinel) return;

      const callback = async (entries) => {
        const [entry] = entries;
        if (
          entry.isIntersecting &&
          !this.loading &&
          (this.moreKeywordAvailable || this.moreDescriptionAvailable)
        ) {
          // Load next batch
          await this.fetchJobsBatch();
        }
      };

      this.observer = new IntersectionObserver(callback, {
        root: null,
        rootMargin: '100px',
        threshold: 0
      });

      this.observer.observe(sentinel);
    },

    async executeSearch() {
      if (!this.isLocationValid) {
        this.error =
          "Please select both a city and a state (or check 'Remote Preference') to search.";
        return;
      }
      this.error = null;
      this.loading = true;

      // Reset everything for a new search
      this.resetPagination();
      this.jobs = [];
      this.otherJobs = [];
      this.displayedJobs = [];
      this.displayedOtherJobs = [];

      try {
        // Fetch first batch of matched jobs
        await this.fetchJobsBatch();

        // Then fetch "other location" jobs if user typed a title
        if (this.searchQueries.title.trim()) {
          const locJobs = await this.fetchOtherJobsInLocation();
          this.otherJobs = locJobs;
          this.displayedOtherJobs = this.otherJobs;
        }
      } catch (err) {
        console.error("Error fetching jobs:", err);
        this.error = "Error fetching jobs: " + err.message;
      } finally {
        this.loading = false;
      }
    },

    resetPagination() {
      this.keywordLastVisible = null;
      this.descriptionLastVisible = null;
      this.moreKeywordAvailable = true;
      this.moreDescriptionAvailable = true;
    },

    /**
     * Fetch the next batch of matched jobs (title + desc) in parallel.
     * Merge results, deduplicate, sort by matchScore + date.
     */
    async fetchJobsBatch() {
      this.loading = true;
      try {
        const rawSearchTerm = this.searchQueries.title.trim().toLowerCase();
        const searchWords = rawSearchTerm.split(/\s+/).filter(Boolean);

        const promises = [];

        // Title-based query
        if (searchWords.length && this.moreKeywordAvailable) {
          promises.push(this.runTitleQuery(searchWords, this.keywordLastVisible));
        } else {
          promises.push(Promise.resolve(null));
        }

        // Description-based or fallback query
        if (searchWords.length && this.moreDescriptionAvailable) {
          promises.push(this.runDescriptionQuery(searchWords, this.descriptionLastVisible));
        } else if (!searchWords.length && this.moreDescriptionAvailable) {
  if (this.isLocationValid) {
    // LOCATION-ONLY SEARCH: user set a city/state or remote only,
    // but no typed title => let fallback query load multiple pages
    promises.push(this.runFallbackQuery(this.descriptionLastVisible));
  } else {
    // TRULY EMPTY SEARCH: no title, no location => skip repeated fallback
    promises.push(Promise.resolve(null));
    this.moreDescriptionAvailable = false;
  }
} else {
  promises.push(Promise.resolve(null));
}


        const [keywordSnap, descSnap] = await Promise.all(promises);
        let newJobs = [];

        // Process keywordSnap
        if (keywordSnap && keywordSnap.docs.length) {
          if (keywordSnap.docs.length < this.batchLimit) {
            this.moreKeywordAvailable = false;
          }
          this.keywordLastVisible = keywordSnap.docs[keywordSnap.docs.length - 1];
          const kwJobs = keywordSnap.docs.map(doc => ({
            docId: doc.id,
            ...doc.data(),
            latitude: doc.data().coordinates?.latitude ?? null,
            longitude: doc.data().coordinates?.longitude ?? null,
            relevanceTier: 3 // "title" relevance
          }));
          newJobs.push(...kwJobs);
        } else if (keywordSnap && keywordSnap.docs.length === 0) {
          this.moreKeywordAvailable = false;
        }

        // Process descSnap
        if (descSnap && descSnap.docs.length) {
          if (descSnap.docs.length < this.batchLimit) {
            this.moreDescriptionAvailable = false;
          }
          this.descriptionLastVisible = descSnap.docs[descSnap.docs.length - 1];
          const descJobs = descSnap.docs.map(doc => ({
            docId: doc.id,
            ...doc.data(),
            latitude: doc.data().coordinates?.latitude ?? null,
            longitude: doc.data().coordinates?.longitude ?? null,
            // if we have a search term, relevanceTier=1 for desc matches
            // if not, fallback => relevanceTier=0
            relevanceTier: searchWords.length ? 1 : 0
          }));
          newJobs.push(...descJobs);
        } else if (descSnap && descSnap.docs.length === 0) {
          this.moreDescriptionAvailable = false;
        }

        // Apply client-side filtering (salary, radius, etc)
        newJobs = this.clientSideFilter(newJobs);

        // Deduplicate with existing
        const existingIds = new Set(this.jobs.map(j => j.docId));
        const freshOnly = newJobs.filter(j => !existingIds.has(j.docId));
        if (!freshOnly.length) {
          return 0;
        }

        // Combine
        let combined = [...this.jobs, ...freshOnly];

        // Compute matchScore
        combined.forEach(job => {
          if (searchWords.length) {
            job.matchScore = this.computeMatchScore(job, searchWords);
          } else {
            if (job.matchScore == null) job.matchScore = 0;
          }
        });

        // Sort by matchScore desc, then date desc
        combined.sort((a, b) => {
          if (b.matchScore !== a.matchScore) return b.matchScore - a.matchScore;
          return b.datePosted.toDate() - a.datePosted.toDate();
        });

        // De-dupe by (title+company)
        const finalList = [];
        const seenCombos = new Set();
        for (const job of combined) {
          const comboKey = (job.title + '__' + job.company).toLowerCase();
          if (!seenCombos.has(comboKey)) {
            finalList.push(job);
            seenCombos.add(comboKey);
          }
        }

        this.jobs = finalList;
        this.displayedJobs = this.jobs; // you can paginate further if you wish

        return freshOnly.length;
      } catch (err) {
        console.error("Error in fetchJobsBatch:", err);
        this.error = err.message || String(err);
        return 0;
      } finally {
        this.loading = false;
      }
    },

    async fetchOtherJobsInLocation() {
      const constraints = this.buildBaseConstraints();
      const q = query(
        collection(db, "jobs"),
        ...constraints,
        orderBy("datePosted", "desc"),
        limit(20)
      );
      let snap;
      try {
        snap = await getDocs(q);
      } catch (err) {
        console.error("Error in fetchOtherJobsInLocation:", err);
        return [];
      }

      let allLocJobs = snap.docs.map(doc => ({
        docId: doc.id,
        ...doc.data(),
        latitude: doc.data().coordinates?.latitude ?? null,
        longitude: doc.data().coordinates?.longitude ?? null
      }));

      // Filter
      allLocJobs = this.clientSideFilter(allLocJobs);

      // Exclude matched
      const matchedIds = new Set(this.jobs.map(j => j.docId));
      const noMatchJobs = allLocJobs.filter(job => !matchedIds.has(job.docId));

      // Sort desc by date
      noMatchJobs.sort((a, b) => b.datePosted.toDate() - a.datePosted.toDate());

      // De-dup by Title+Company
      const dedupedOtherJobs = [];
      const seenCombos = new Set();
      for (const job of noMatchJobs) {
        const comboKey = (job.title + '__' + job.company).toLowerCase();
        if (!seenCombos.has(comboKey)) {
          dedupedOtherJobs.push(job);
          seenCombos.add(comboKey);
        }
      }
      return dedupedOtherJobs;
    },

    // Title query
    runTitleQuery(searchWords, startDoc) {
      const constraints = this.buildBaseConstraints();
      constraints.push(where("titleKeywords", "array-contains-any", searchWords));
      const q = this.buildQuery(constraints, startDoc);
      return getDocs(q);
    },
    // Description/skills query
    runDescriptionQuery(searchWords, startDoc) {
      const constraints = this.buildBaseConstraints();
      constraints.push(where("allSkills", "array-contains-any", searchWords));
      const q = this.buildQuery(constraints, startDoc);
      return getDocs(q);
    },
    // Fallback (no search term)
    runFallbackQuery(startDoc) {
      const constraints = this.buildBaseConstraints();
      const q = this.buildQuery(constraints, startDoc);
      return getDocs(q);
    },

    buildBaseConstraints() {
      const constraints = [];
      constraints.push(where("isActive", "==", true));

      // Salary
      if (this.filters.salaryMin) {
        constraints.push(where("salaryRange.max", ">=", parseInt(this.filters.salaryMin)));
      }
      if (this.filters.salaryMax) {
        constraints.push(where("salaryRange.min", "<=", parseInt(this.filters.salaryMax)));
      }

      // Date posted
      const days = parseInt(this.filters.datePosted);
      const cutoff = new Date();
      cutoff.setDate(cutoff.getDate() - days);
      constraints.push(where("datePosted", ">=", cutoff));

      // If not remoteOnly, add bounding box constraints
      if (
        !this.locationFilter.isRemoteOnly &&
        this.locationFilter.coordinates &&
        this.locationFilter.coordinates.latitude != null &&
        this.locationFilter.coordinates.longitude != null &&
        this.locationFilter.radius
      ) {
        const { latitude, longitude } = this.locationFilter.coordinates;
        const R = 3959; // miles
        const deltaLat = (this.locationFilter.radius / R) * (180 / Math.PI);
        const deltaLon =
          (this.locationFilter.radius / (R * Math.cos((latitude * Math.PI) / 180))) *
          (180 / Math.PI);

        const minLat = latitude - deltaLat;
        const maxLat = latitude + deltaLat;
        const minLon = longitude - deltaLon;
        const maxLon = longitude + deltaLon;

        constraints.push(where("coordinates.latitude", ">=", minLat));
        constraints.push(where("coordinates.latitude", "<=", maxLat));
        constraints.push(where("coordinates.longitude", ">=", minLon));
        constraints.push(where("coordinates.longitude", "<=", maxLon));
      }else if (this.locationFilter.isRemoteOnly) {
    // -- Remote-only logic --
    // Make sure your Firestore docs have "remote" = true if
    // they are fully remote or "globallyRemote."
    // If you only store "globallyRemote" or some other field,
    // adjust accordingly.
    constraints.push(where("remote", "==", true));

    // Also require matching the chosen state
    constraints.push(where("state", "==", this.locationFilter.state));
  }

  return constraints;
},

    buildQuery(constraints, startDoc, orderField = "datePosted", orderDirection = "desc") {
      let base = query(
        collection(db, "jobs"),
        ...constraints,
        orderBy(orderField, orderDirection),
        limit(this.batchLimit)
      );
      if (startDoc) {
        base = query(
          collection(db, "jobs"),
          ...constraints,
          orderBy(orderField, orderDirection),
          startAfter(startDoc),
          limit(this.batchLimit)
        );
      }
      return base;
    },

    clientSideFilter(jobList) {
  const days = parseInt(this.filters.datePosted);
  const cutoff = new Date();
  cutoff.setDate(cutoff.getDate() - days);

  return jobList.filter(job => {
    // 1) Date posted
    const isWithinDate =
      !job.datePosted || job.datePosted.toDate() >= cutoff;

    // 2) Salary
    const minOK = parseInt(this.filters.salaryMin) || 0;
    const maxOK = parseInt(this.filters.salaryMax) || 9999999;
    const meetsSalary =
      job.salaryRange.max >= minOK &&
      job.salaryRange.min <= maxOK;

    // 3) Industry
    let meetsIndustry = true;
    if (this.filters.industries.length > 0) {
      meetsIndustry = this.filters.industries.includes(job.industry);
    }

    // 4) Job type
    let meetsJobType = true;
    if (this.filters.jobTypes.length > 0) {
      meetsJobType = this.filters.jobTypes.includes(job.type);
    }

    // 5) Experience
    let meetsExperience = true;
    if (this.filters.experiences.length > 0) {
      meetsExperience = this.filters.experiences.includes(job.experience);
    }

    // 6) Location logic
    let meetsLocation = true;
    if (this.locationFilter.isRemoteOnly) {
      // a) Only allow remote jobs
      // b) Must also match the user-selected state
      meetsLocation =
        (job.remote || job.globallyRemote) &&
        job.state === this.locationFilter.state;
    } else {
      // Normal bounding-box or same-state check
      if (
        job.latitude != null &&
        job.longitude != null &&
        this.locationFilter.coordinates
      ) {
        const distance = this.haversineDistance(
          this.locationFilter.coordinates.latitude,
          this.locationFilter.coordinates.longitude,
          job.latitude,
          job.longitude
        );
        meetsLocation = distance <= this.locationFilter.radius;
      } else {
        // fallback to state comparison if lat/long is missing
        meetsLocation = job.state === this.locationFilter.state;
      }
    }

    return (
      isWithinDate &&
      meetsSalary &&
      meetsLocation &&
      meetsIndustry &&
      meetsJobType &&
      meetsExperience
    );
  });
},


    haversineDistance(lat1, lon1, lat2, lon2) {
      const R = 3959; // miles
      const dLat = (lat2 - lat1) * (Math.PI / 180);
      const dLon = (lon2 - lon1) * (Math.PI / 180);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) *
          Math.cos(lat2 * (Math.PI / 180)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c;
    },

    formatDate(timestamp) {
      if (!timestamp) return '';
      const date = timestamp.toDate();
      const now = new Date();
      const diffTime = Math.abs(now - date);
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays === 0) return 'Today';
      if (diffDays === 1) return 'Yesterday';
      if (diffDays < 7) return `${diffDays} days ago`;
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    },

    // Multi-apply
    applyToAllSelectedJobs() {
      this.$router.push({
        name: 'MultiApply',
        query: { jobs: this.selectedJobs.join(',') }
      });
    },
    clearSelections() {
      this.selectedJobs = [];
    },

    // Carousel next
    nextJob(type, companyName) {
      let jobs;
      if (type === 'matched') {
        jobs = this.groupedMatchedJobs[companyName] || [];
      } else {
        jobs = this.groupedUnmatchedJobs[companyName] || [];
      }
      if (!jobs.length) return;

      const currentIndex = this.carouselIndex[type][companyName];
      const nextIndex = (currentIndex + 1) % jobs.length;
      this.carouselIndex[type][companyName] = nextIndex;
    },

    getDots(count) {
      const maxDots = 5;
      return new Array(Math.min(count, maxDots)).fill(0);
    },

    onCardClick(job) {
      // On touch devices, let a card click open the modal
      if (window.matchMedia('(hover: none), (pointer: coarse)').matches) {
        this.viewJobDetails(job);
      }
    }
  }
};
</script>

<style scoped>
/* Your existing styles (with or without "scoped") */

/* Example from your snippet: */

* {
  box-sizing: border-box;
}

body,
.jobswipe {
  font-family: 'FixelDisplay', sans-serif;
  background-color: #f3ede2;
  margin: 0;
  padding: 0;
}

.intro-banner-container {
  width: 100%;
  margin: 1.5rem 0;
  background: linear-gradient(to right, #ffe8cc 0%, #ffd6a4 100%);
  border: 2px solid #fc7115;
  border-radius: 0.5rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.intro-banner {
  max-width: 72rem;
  margin: 0 auto;
  padding: 3rem 1rem;
  text-align: center;
}

.intro-banner__main-title {
  font-size: 3rem;
  font-weight: 900;
  color: #fc7115;
  margin-bottom: 1rem;
  letter-spacing: -1px;
}
.intro-banner__subtitle {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
}
.intro-banner__tagline {
  margin-top: 1.5rem;
  font-size: 1rem;
  color: #333;
  font-style: italic;
}

.intro-cards {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  max-width: 72rem;
  margin: 0 auto 2rem;
  /* This ensures the cards stretch to match the tallest one */
  align-items: stretch;
}
@media (min-width: 768px) {
  .intro-cards {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch; /* important for equal-height columns */
  }
}
.intro-card {
  /* Let each card flex and fill the available space */
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  text-align: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease;
}
.intro-card:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
.intro-card__icon {
  /* Existing properties */
  width: 2rem;
  height: 2rem;
  color: #fc7115;
  margin-bottom: 0.75rem;

  /* Add these: */
  display: block;       /* makes the SVG a block element */
  margin-left: auto;    /* pushes it away from left edge */
  margin-right: auto;   /* pushes it away from right edge */
}

.intro-card__title {
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: 700;
}
.intro-card__text {
  font-size: 1rem;
  line-height: 1.5;
}

.search-section-container {
  max-width: 72rem;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
}

.search-section {
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  padding: 2rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 1px 2px rgb(0 0 0 / 0.05);
}

.search-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.search-title {
  font-size: 2rem;
  font-weight: 700;
  color: #fc7115;
  margin-bottom: 2rem;
}

.search-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.search-section-label {
  font-size: 1.125rem;
  font-weight: 700;
  color: #333;
  margin-top: 1rem;
}

.search-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #666;
}

.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input,
.search-select,
.multiselect-custom {
  width: 100%;
  padding: 0.875rem 1rem;
  padding-left: 2.5rem; /* icon space */
  border: 1px solid rgb(209, 213, 219);
  border-radius: 0.5rem;
  background-color: white;
  outline: none;
  font-size: 1rem;
}

.search-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: rgb(156, 163, 175);
  width: 1.25rem;
  height: 1.25rem;
  pointer-events: none;
  z-index: 1;
}

.search-select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg ... %3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1em;
  padding-right: 2.5rem;
}

.search-input:focus,
.search-select:focus,
.multiselect-custom:focus-within {
  border-color: rgb(59, 130, 246);
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
}

.search-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}
@media (min-width: 640px) {
  .search-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.salary-range {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}
@media (min-width: 640px) {
  .salary-range {
    grid-template-columns: repeat(2, 1fr);
  }
}

.search-button {
  padding: 0.75rem 1.5rem;
  background-color: #fc7115;
  color: white;
  font-weight: 500;
  border-radius: 0.5rem;
  transition: background-color 0.2s;
  width: fit-content;
  align-self: flex-start;
}
.search-button:hover:not(:disabled) {
  background-color: #e35d0a;
}

.error-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.error-modal__content {
  background: #fff;
  padding: 2rem;
  border-radius: 0.5rem;
}
.error-modal__button {
  background-color: #fc7115;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
}

.jobswipe__main {
  max-width: 72rem; 
  margin: 0 auto;
  padding: 1.5rem 1rem;
}
.jobs-loading {
  display: grid;
  gap: 1rem;
}
.jobs-loading__card {
  background: #fff;
  border-radius: 0.5rem;
  height: 150px;
  padding: 1rem;
  animation: pulse 1.5s infinite ease-in-out;
}
.jobs-error,
.jobs-empty {
  text-align: center;
  font-size: 1.25rem;
  color: #333;
}
.matched-jobs-section {
  margin-bottom: 2rem;
}

.company-groups {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-top: 1rem;
}
@media (max-width: 767px) {
  .company-groups {
    grid-template-columns: 1fr;
  }
}
.company-group {
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px rgb(0 0 0 / 0.05);
}
.company-group h4 {
  margin: 0;
  font-size: 1.25rem;
  color: #fc7115;
  margin-bottom: 1rem;
}

.dot-indicators {
  display: flex;
  gap: 0.25rem;
}
.dot {
  width: 0.75rem;
  height: 0.75rem;
  background-color: #d1d5db;
}
.dot.active {
  background-color: #fc7115;
}
.carousel-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}
.carousel-next-button {
  background-color: #fc7115;
  color: #fff;
  border: none;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
}
.carousel-next-button:hover {
  background-color: #e35d0a;
}

.carousel-job-card {
  position: relative;
  width: 100%;
  height: 420px; 
  overflow: hidden;
  margin-bottom: 1rem;
}
.job-card[v-show="false"] {
  display: none !important;
}
.job-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  padding: 0.75rem;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  transition: box-shadow 0.2s, background-color 0.2s;
}
.job-card:hover {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  background-color: rgba(229, 231, 235, 0.6);
}
.job-card__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
  color: #000;
}
.job-card__title {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: #000 !important;
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.job-card__actions {
  flex-shrink: 0;
}
.job-card__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}
.job-card__tag {
  background: none;
  border: none;
  padding: 0;
  font-style: italic;
  color: #555;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}
.job-card__description {
  color: #555;
  margin-bottom: 0.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.75rem;
}
.job-card__skills {
  margin-bottom: 0.5rem;
}
.job-card__skills-title {
  font-size: 0.875rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.25rem;
}
.job-card__skills-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  white-space: normal;
}
.job-card__skill-tag {
  display: inline-block;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid #ddd;
  background-color: #f3f3f3;
  color: #333;
  max-width: 130px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.job-card__cta {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  background-color: #fc7115;
  color: white;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out, background-color 0.2s;
  opacity: 0;
  pointer-events: none;
}
.job-card:hover .job-card__cta {
  opacity: 1;
  pointer-events: auto;
}
.job-card__cta:hover {
  background-color: #e35d0a;
}

.job-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.job-modal__content {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}
.job-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.job-modal__title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fc7115;
}
.job-modal__company {
  font-weight: 500;
  color: #333;
  margin-top: 0.25rem;
}
.job-modal__close {
  background: none;
  border: none;
  cursor: pointer;
}
.job-modal__details {
  margin-top: 1.5rem;
}
.job-modal__info > p {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}
.job-modal__section {
  margin-top: 1.5rem;
}
.job-modal__subtitle {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}
.job-modal__description {
  color: #555;
}
.job-modal__skills {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.job-modal__skill-tag {
  background-color: rgb(243, 244, 246);
  color: rgb(55, 65, 81);
  padding: 0.25rem 0.75rem;
  border-radius: 0px;
  font-size: 0.875rem;
}

.job-modal__actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}
.job-modal__actions--split {
  display: flex;
  align-items: center;
}
.job-modal__actions-left {
  margin-right: auto;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.job-modal__actions-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.job-modal__company-site {
  background-color: #fff;
  color: #fc7115;
  border: 1px solid #fc7115;
  transition: background-color 0.2s;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
}
.job-modal__company-site:hover {
  background-color: #fc711510;
}
.job-modal__apply {
  background-color: #fc7115;
  color: white;
  border: none;
}
.job-modal__apply:hover {
  background-color: #e35d0a;
}
.job-modal__share {
  background-color: #3b82f6;
  color: white;
  border: none;
}
.job-modal__share:hover {
  background-color: #2563eb;
}
.job-modal__share svg {
  width: 1em;
  height: 1em;
}

.icon-class {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  margin-right: 0.5rem;
}

/* Pulse animation for loading states */
@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}

/* Multi-apply footer */
.multi-apply-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fc7115;
  color: #fff;
  padding: 1rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  z-index: 999;
}
.multi-apply-footer > div {
  display: flex;
  gap: 1rem;
}
.multi-apply-footer button {
  background-color: #fff;
  color: #fc7115;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: none;
  font-weight: 600;
  cursor: pointer;
}
.multi-apply-footer button:hover {
  opacity: 0.9;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .search-title {
    font-size: 1rem;
    text-align: center;
  }
  .company-groups {
    grid-template-columns: 1fr;
  }
}

.job-select-checkbox {
  width: 1.25rem;
  height: 1.25rem;
}
</style>
