// Import the functions you need from the SDKs
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth'

// Firebase configuration for the correct Fylter project
const firebaseConfig = {
  apiKey: "AIzaSyDSA0yEfSaEeXIvS7mGXZJ5Js8-Si2mMsE",
  authDomain: "job-board-38af4.firebaseapp.com",
  projectId: "job-board-38af4",
  storageBucket: "job-board-38af4.appspot.com",
  messagingSenderId: "923505374158",
  appId: "1:923505374158:web:b06a109a207b18baa0b19f",
  measurementId: "G-DCQ9NNRFDE"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firestore and Storage
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp); 
const auth = getAuth(firebaseApp)

// Export Firestore and Storage
export { db, storage, auth };
