<template>
  <div class="edit-profile-container">
    <!-- Title + Stepper Row -->
    <div class="header-row">
      <h1 class="page-title">Edit Your Profile</h1>
      <div class="progress-container">
        <div class="stepper">
          <div
            v-for="(step, index) in stepOrder"
            :key="step"
            :class="[ 'step', { completed: isStepCompleted(step), active: activeTab === step } ]"
            @click="setActiveTab(step)"
          >
            <div class="step-indicator">
              <span v-if="isStepCompleted(step)" class="step-check">✓</span>
              <span v-else>{{ index + 1 }}</span>
            </div>
            <span class="step-label">{{ stepLabels[step] }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- IF NOT LOGGED IN, SHOW “PLEASE LOG IN” PROMPT -->
    <div v-if="!currentUser" class="not-logged-in">
      <div class="empty-state">
        <svg xmlns="http://www.w3.org/2000/svg" class="empty-icon" viewBox="0 0 24 24">
          <path
            d="M12 2C6.48 2 2 6.48 
               2 12s4.48 10 10 
               10 10-4.48 10-10S17.52 
               2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 
               8-8 8 3.59 8 8-3.59 
               8-8 8zm-1-13h2v6h-2zm0 
               8h2v2h-2z"
          />
        </svg>
        <h2>Authentication Required</h2>
        <p>
          Please
          <router-link to="/login" class="highlight-link">log in</router-link>
          to edit your profile.
        </p>
      </div>
    </div>

    <!-- ELSE, RENDER MAIN EDIT PROFILE CONTENT (Personal, Compliance, Job Alerts) -->
    <div v-else class="main-layout">
      <!-- Sidebar for larger screens -->
      <div class="sidebar">
        <div class="sidebar-nav">
          <button
            v-for="(label, tab) in stepLabels"
            :key="tab"
            :class="['nav-item', { active: activeTab === tab }]"
            @click="setActiveTab(tab)"
          >
            <!-- Minimal icons for each tab -->
            <svg v-if="tab === 'personal'" xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 24 24">
              <path
                d="M12 12c2.7 0 5-2.24 
                   5-5s-2.3-5-5-5-5 
                   2.24-5 5 2.3 5 5 5zm0 
                   2c-2.66 0-8 1.34-8 4v2h16v-2c0-2.66-5.34-4-8-4z"
              />
            </svg>
            <svg v-else-if="tab === 'compliance'" xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 24 24">
              <path
                d="M12 2l-5.5 3v6c0 5.5 
                   4 10.74 5.5 12 
                   1.5-1.26 5.5-6.5 
                   5.5-12V5L12 2z"
              />
            </svg>
            <svg v-else-if="tab === 'jobAlerts'" xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 24 24">
              <path
                d="M12 2C8.13 2 5 5.13 
                   5 9c0 5.25 7 13 
                   7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 
                   9.2c-1.21 0-2.2-.99-2.2-2.2s.99-2.2 
                   2.2-2.2 2.2.99 2.2 2.2-.99 2.2-2.2 2.2z"
              />
            </svg>
            <span>{{ label }}</span>
            <div class="completion-indicator" v-if="isStepCompleted(tab)">✓</div>
          </button>

          <!-- Sign Out button -->
          <button class="nav-item signout-btn" @click="handleSignOut">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 24 24">
              <path
                d="M13 3c-1.1 0-2 .9-2 
                   2v3H5.01c-1.1 0-2 .9-2 
                   2v6c0 1.1.9 2 2 2H11v3
                   c0 1.1.9 2 2 2s2-.9 
                   2-2V5c0-1.1-.9-2-2-2z"
              />
            </svg>
            Sign Out
          </button>
        </div>
      </div>

      <!-- Main content area -->
      <div class="content-area">
        <!-- Tabs for mobile -->
        <div class="mobile-tabs">
          <select v-model="activeTab" class="mobile-tab-selector">
            <option v-for="(label, tab) in stepLabels" :key="tab" :value="tab">
              {{ label }}
            </option>
          </select>
        </div>

        <!-- Content with transition effects -->
        <transition name="fade" mode="out-in">
          <!-- ==================== PERSONAL TAB ==================== -->
          <div v-if="activeTab === 'personal'" class="tab-panel" key="personal">
            <!-- Personal Info Card -->
            <section class="card">
              <h2 class="section-title">Basic Information</h2>
              <p class="section-description">
                Provide your personal details so we can autofill applications for you.
              </p>
              <div class="form-divider"></div>

              <div class="two-column-grid">
                <div class="form-group">
                  <label>First Name <span class="required">*</span></label>
                  <input
                    v-model="profileData.firstName"
                    type="text"
                    placeholder="John"
                    required
                    class="input-field"
                    :class="{ 'error': validationErrors.firstName }"
                  />
                  <span v-if="validationErrors.firstName" class="error-text">
                    {{ validationErrors.firstName }}
                  </span>
                </div>

                <div class="form-group">
                  <label>Last Name <span class="required">*</span></label>
                  <input
                    v-model="profileData.lastName"
                    type="text"
                    placeholder="Doe"
                    required
                    class="input-field"
                    :class="{ 'error': validationErrors.lastName }"
                  />
                  <span v-if="validationErrors.lastName" class="error-text">
                    {{ validationErrors.lastName }}
                  </span>
                </div>
              </div>

              <div class="two-column-grid">
                <div class="form-group">
                  <label>Email <span class="required">*</span></label>
                  <div class="input-with-icon">
                    <input
                      v-model="profileData.email"
                      type="email"
                      placeholder="john.doe@example.com"
                      required
                      class="input-field"
                      :class="{ 'error': validationErrors.email }"
                    />
                    <svg xmlns="http://www.w3.org/2000/svg" class="field-icon" viewBox="0 0 24 24">
                      <path
                        d="M20 4H4c-1.1 
                           0-1.99.9-1.99 
                           2L2 18c0 1.1.9 2 
                           2 2h16c1.1 0 
                           2-.9 2-2V6c0-1.1-.9-2-2-2zm0 
                           4l-8 5-8-5V6l8 
                           5 8-5v2z"
                      />
                    </svg>
                  </div>
                  <span v-if="validationErrors.email" class="error-text">
                    {{ validationErrors.email }}
                  </span>
                </div>

                <div class="form-group">
                  <label>Phone Number</label>
                  <div class="input-with-icon">
                    <input
                      v-model="profileData.phoneNumber"
                      type="tel"
                      placeholder="+1 (555) 123-4567"
                      class="input-field"
                    />
                    <svg xmlns="http://www.w3.org/2000/svg" class="field-icon" viewBox="0 0 24 24">
                      <path
                        d="M6.62 10.79c1.44 
                           2.83 3.76 5.14 
                           6.59 6.59l2.2-2.2c.27-.27.67-.36 
                           1.02-.24 1.12.37 
                           2.33.57 3.57.57.55 
                           0 1 .45 1 1V20c0 
                           .55-.45 1-1 
                           1-9.39 0-17-7.61-17-17 
                           0-.55.45-1 1-1h3.5c.55 
                           0 1 .45 1 1 0 
                           1.25.2 2.45.57 
                           3.57.11.35.03.74-.25 
                           1.02l-2.2 2.2z"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <!-- Personal Location (City) -->
              <div class="form-group" style="position: relative;">
                <label>Location <span class="required">*</span></label>
                <input
                  v-model="locationQuery"
                  type="text"
                  placeholder="Start typing a city name..."
                  class="input-field"
                  :class="{ 'error': validationErrors.location }"
                  @input="filterCitySuggestions"
                  @blur="handleLocationBlur"
                />
                <!-- Suggestions dropdown -->
                <div v-if="showSuggestions && filteredCities.length" class="location-suggestions">
                  <div
                    v-for="(cityObj, idx) in filteredCities"
                    :key="idx"
                    class="suggestion-item"
                    @mousedown.prevent="selectCity(cityObj)"
                  >
                    {{ cityObj.cityName }},{{ cityObj.state }}
                  </div>
                </div>
                <span v-if="validationErrors.location" class="error-text">
                  {{ validationErrors.location }}
                </span>
                <!-- Show chosen city + state if selected -->
                <div v-if="selectedCityObj" class="helper-text">
                  You selected:
                  <strong>{{ selectedCityObj.cityName }},{{ selectedCityObj.state }}</strong>
                </div>
              </div>

              <!-- Save Basic Info button -->
              <div class="section-actions">
                <button class="btn primary-btn" :disabled="saving" @click="saveBasicInfo">
                  <span v-if="!saving">Save Basic Info</span>
                  <span v-else class="loading-spinner"></span>
                </button>
                <span v-if="partialSaveStatus.basicInfo" class="save-indicator">
                  <svg xmlns="http://www.w3.org/2000/svg" class="save-icon" viewBox="0 0 24 24">
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                  </svg>
                  Saved
                </span>
              </div>
            </section>

            <!-- Resume Card -->
            <section class="card">
              <h2 class="section-title">Resume</h2>
              <p class="section-description">Upload your most recent resume.</p>
              <div class="form-divider"></div>

              <div class="resume-section">
                <div class="resume-info">
                  <h3 class="subsection-title">Current Resume</h3>
                  <div class="document-display">
                    <svg xmlns="http://www.w3.org/2000/svg" class="document-icon" viewBox="0 0 24 24">
                      <path
                        d="M14 2H6c-1.1 
                           0-1.99.9-1.99 
                           2L4 20c0 
                           1.1.89 2 
                           1.99 
                           2H18c1.1 
                           0 2-.9 
                           2-2V8l-6-6zm2 
                           16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 
                           9H13z"
                      />
                    </svg>
                    <div class="document-info">
                      <template v-if="profileData.resumeUrl">
                        <span class="document-name">{{ getResumeFileName() }}</span>
                        <a :href="profileData.resumeUrl" target="_blank" class="document-link">
                          View/Download
                        </a>
                      </template>
                      <template v-else>
                        <span class="document-empty">No resume uploaded yet</span>
                      </template>
                    </div>
                  </div>
                </div>

                <div class="upload-section">
                  <h3 class="subsection-title">Upload New Resume</h3>
                  <div class="file-upload-container">
                    <label for="resume-upload" class="file-upload-label">
                      <svg xmlns="http://www.w3.org/2000/svg" class="upload-icon" viewBox="0 0 24 24">
                        <path
                          d="M19.35 10.04C18.67 
                             6.59 15.64 4 12 
                             4 9.11 4 6.6 
                             5.64 5.35 
                             8.04 2.34 
                             8.36 0 
                             10.91 0 
                             14c0 3.31 
                             2.69 6 6 
                             6h13c2.76 
                             0 5-2.24 
                             5-5 
                             0-2.64-2.05-4.78-4.65-4.96zM14 
                             13v4h-4v-4H7l5-5 
                             5 5h-3z"
                        />
                      </svg>
                      <span>Select PDF or DOCX</span>
                    </label>
                    <input
                      id="resume-upload"
                      type="file"
                      @change="handleResumeFile"
                      accept=".pdf,.docx"
                      class="hidden-file-input"
                    />
                  </div>

                  <div v-if="resumeFileName" class="selected-file">
                    <svg xmlns="http://www.w3.org/2000/svg" class="file-icon" viewBox="0 0 24 24">
                      <path
                        d="M14 2H6c-1.1 
                           0-1.99.9-1.99 
                           2L4 20c0 
                           1.1.89 2 
                           1.99 
                           2H18c1.1 0 
                           2-.9 
                           2-2V8l-6-6zm-2 
                           16c-2.05 
                           0-3.71-1.67-3.71-3.71s1.67-3.71 
                           3.71-3.71 
                           3.71 1.67 
                           3.71 
                           3.71-1.66 
                           3.71-3.71 
                           3.71zm3-10H7V4h8v4z"
                      />
                    </svg>
                    <span>{{ resumeFileName }}</span>
                    <button class="btn text-btn" @click="clearResumeFile">
                      <svg xmlns="http://www.w3.org/2000/svg" class="clear-icon" viewBox="0 0 24 24">
                        <path
                          d="M19 6.41L17.59 
                             5 12 10.59 
                             6.41 5 5 
                             6.41 10.59 
                             12 5 
                             17.59 6.41 
                             19 12 
                             13.41 17.59 
                             19 19 
                             17.59 
                             13.41 12z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <!-- Save Resume button -->
              <div class="section-actions">
                <button
                  class="btn primary-btn"
                  :disabled="saving || !resumeFile"
                  @click="saveResume"
                >
                  <span v-if="!saving">Upload & Save Resume</span>
                  <span v-else class="loading-spinner"></span>
                </button>
                <span v-if="partialSaveStatus.resume" class="save-indicator">
                  <svg xmlns="http://www.w3.org/2000/svg" class="save-icon" viewBox="0 0 24 24">
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                  </svg>
                  Saved
                </span>
              </div>
            </section>

            <!-- Referral Code (if present) -->
            <section class="card" v-if="profileData.referralCode">
              <h2 class="section-title">Referral Code</h2>
              <p class="section-description">Share this code to earn bonuses.</p>
              <div class="form-divider"></div>

              <div class="referral-container">
                <div class="referral-row">
                  <input :value="profileData.referralCode" readonly class="referral-field" />
                  <button type="button" class="btn icon-btn" @click="copyReferralCode">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 24 24">
                      <path
                        d="M16 1H4c-1.1 
                           0-2 
                           .9-2 
                           2v18c0 
                           1.11.9 
                           2 2 
                           2h12c1.1 
                           0 
                           2-.89 
                           2-2V3c0-1.1-.9-2-2-2zm-6 
                           2h2v2h-2V3zm6 
                           16H4V5h2v2h4V5h2v2h4v12z"
                      />
                    </svg>
                    Copy Code
                  </button>
                </div>
                <div v-if="codeCopied" class="copy-confirmation">Code copied to clipboard!</div>
              </div>
            </section>

            <!-- Navigation actions for Personal tab -->
            <div class="navigation-actions">
              <!-- No "Back" button on first step -->
              <div></div>
              <!-- Next goes to COMPLIANCE -->
              <button class="btn next-btn" @click="navigateToTab('compliance')">
                Next: Compliance
                <svg xmlns="http://www.w3.org/2000/svg" class="nav-icon" viewBox="0 0 24 24">
                  <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                </svg>
              </button>
            </div>
          </div>

          <!-- ==================== COMPLIANCE TAB ==================== -->
          <div v-else-if="activeTab === 'compliance'" class="tab-panel" key="compliance">
            <section class="card">
              <h2 class="section-title">Compliance</h2>
              <p class="section-description">
                These fields are optional but most job advertisements require them.
              </p>
              <div class="form-divider"></div>

              <!-- Demographics subsection -->
              <div class="subsection">
                <h3 class="subsection-title">Demographics</h3>
                <div class="info-tooltip">
                  <svg xmlns="http://www.w3.org/2000/svg" class="info-icon" viewBox="0 0 24 24">
                    <path
                      d="M12 2C6.48 2 
                         2 6.48 
                         2 12s4.48 10 
                         10 10 10-4.48 
                         10-10S17.52 
                         2 12 
                         2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
                    />
                  </svg>
                  <span class="tooltip-text">
                    This information is for statistical purposes only and does not affect hiring decisions.
                  </span>
                </div>

                <div class="two-column-grid">
                  <div class="form-group">
                    <label>Gender</label>
                    <div class="select-wrapper">
                      <select v-model="complianceData.gender" class="select-field">
                        <option value="">(Select)</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Non-binary</option>
                        <option>I do not wish to answer</option>
                      </select>
                      <svg xmlns="http://www.w3.org/2000/svg" class="select-arrow" viewBox="0 0 24 24">
                        <path d="M7 10l5 5 5-5z" />
                      </svg>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Race</label>
                    <div class="select-wrapper">
                      <select v-model="complianceData.race" class="select-field">
                        <option value="">(Select)</option>
                        <option>White</option>
                        <option>Black or African American</option>
                        <option>Hispanic or Latino</option>
                        <option>Asian</option>
                        <option>Native American or Alaska Native</option>
                        <option>Two or more races</option>
                        <option>I do not wish to answer</option>
                      </select>
                      <svg xmlns="http://www.w3.org/2000/svg" class="select-arrow" viewBox="0 0 24 24">
                        <path d="M7 10l5 5 5-5z" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div class="two-column-grid">
                  <div class="form-group">
                    <label>Veteran Status</label>
                    <div class="select-wrapper">
                      <select v-model="complianceData.veteranStatus" class="select-field">
                        <option value="">(Select)</option>
                        <option>Not a Veteran</option>
                        <option>Veteran</option>
                        <option>I do not wish to answer</option>
                      </select>
                      <svg xmlns="http://www.w3.org/2000/svg" class="select-arrow" viewBox="0 0 24 24">
                        <path d="M7 10l5 5 5-5z" />
                      </svg>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Are you disabled?</label>
                    <div class="select-wrapper">
                      <select v-model="complianceData.isDisabled" class="select-field">
                        <option value="">(Select)</option>
                        <option>No</option>
                        <option>Yes</option>
                        <option>I do not wish to answer</option>
                      </select>
                      <svg xmlns="http://www.w3.org/2000/svg" class="select-arrow" viewBox="0 0 24 24">
                        <path d="M7 10l5 5 5-5z" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div class="two-column-grid">
                  <div class="form-group">
                    <label>Requires Sponsorship?</label>
                    <div class="select-wrapper">
                      <select v-model="complianceData.requiresSponsorship" class="select-field">
                        <option value="">(Select)</option>
                        <option>true</option>
                        <option>false</option>
                      </select>
                      <svg xmlns="http://www.w3.org/2000/svg" class="select-arrow" viewBox="0 0 24 24">
                        <path d="M7 10l5 5 5-5z" />
                      </svg>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Authorized to Work?</label>
                    <div class="select-wrapper">
                      <select v-model="complianceData.isAuthorizedToWork" class="select-field">
                        <option value="">(Select)</option>
                        <option>true</option>
                        <option>false</option>
                      </select>
                      <svg xmlns="http://www.w3.org/2000/svg" class="select-arrow" viewBox="0 0 24 24">
                        <path d="M7 10l5 5 5-5z" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div class="two-column-grid">
                  <div class="form-group">
                    <label>At least 18?</label>
                    <div class="select-wrapper">
                      <select v-model="complianceData.isAtLeast18" class="select-field">
                        <option value="">(Select)</option>
                        <option>true</option>
                        <option>false</option>
                      </select>
                      <svg xmlns="http://www.w3.org/2000/svg" class="select-arrow" viewBox="0 0 24 24">
                        <path d="M7 10l5 5 5-5z" />
                      </svg>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>At least 21?</label>
                    <div class="select-wrapper">
                      <select v-model="complianceData.isAtLeast21" class="select-field">
                        <option value="">(Select)</option>
                        <option>true</option>
                        <option>false</option>
                      </select>
                      <svg xmlns="http://www.w3.org/2000/svg" class="select-arrow" viewBox="0 0 24 24">
                        <path d="M7 10l5 5 5-5z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <!-- Section Save button -->
            <div class="section-actions">
              <button class="btn primary-btn" :disabled="saving" @click="saveCompliance">
                <span v-if="!saving">Save Compliance</span>
                <span v-else class="loading-spinner"></span>
              </button>
              <span v-if="partialSaveStatus.compliance" class="save-indicator">
                <svg xmlns="http://www.w3.org/2000/svg" class="save-icon" viewBox="0 0 24 24">
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
                Saved
              </span>
            </div>

            <!-- Navigation actions: Back to personal, forward to job alerts -->
            <div class="navigation-actions">
              <button class="btn back-btn" @click="navigateToTab('personal')">
                <svg xmlns="http://www.w3.org/2000/svg" class="nav-icon" viewBox="0 0 24 24">
                  <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                </svg>
                Back: Personal
              </button>
              <button class="btn next-btn" @click="navigateToTab('jobAlerts')">
                Next: Job Alerts
                <svg xmlns="http://www.w3.org/2000/svg" class="nav-icon" viewBox="0 0 24 24">
                  <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                </svg>
              </button>
            </div>
          </div>

          <!-- ==================== JOB ALERTS TAB ==================== -->
          <div v-else-if="activeTab === 'jobAlerts'" class="tab-panel" key="jobAlerts">
            <section class="card">
              <h2 class="section-title">Coming soon: Job Alerts</h2>
              <p class="section-description">
                Configure your daily job alert preferences. 
                (Title, Location, and Salary are required if alerts are enabled.)
              </p>
              <div class="form-divider"></div>

              <!-- Enable checkbox -->
              <div class="form-group">
                <label>Enable Daily Alerts?</label>
                <input type="checkbox" v-model="jobAlertData.isEnabled" />
              </div>

              <!-- Title (required if isEnabled=true) -->
              <div class="form-group">
                <label>Job Title <span v-if="jobAlertData.isEnabled" class="required">*</span></label>
                <input
                  v-model="jobAlertData.title"
                  type="text"
                  placeholder="e.g. 'Frontend Developer'"
                  class="input-field"
                  :class="{ 'error': jobAlertErrors.title }"
                />
                <span v-if="jobAlertErrors.title" class="error-text">
                  {{ jobAlertErrors.title }}
                </span>
              </div>

              <!-- Salary Range -->
              <div class="two-column-grid">
                <div class="form-group">
                  <label>Min Salary <span v-if="jobAlertData.isEnabled" class="required">*</span></label>
                  <input
                    v-model.number="jobAlertData.salaryMin"
                    type="number"
                    min="0"
                    class="input-field"
                    :class="{ 'error': jobAlertErrors.salaryMin }"
                  />
                  <span v-if="jobAlertErrors.salaryMin" class="error-text">
                    {{ jobAlertErrors.salaryMin }}
                  </span>
                </div>
                <div class="form-group">
                  <label>Max Salary <span v-if="jobAlertData.isEnabled" class="required">*</span></label>
                  <input
                    v-model.number="jobAlertData.salaryMax"
                    type="number"
                    min="0"
                    class="input-field"
                    :class="{ 'error': jobAlertErrors.salaryMax }"
                  />
                  <span v-if="jobAlertErrors.salaryMax" class="error-text">
                    {{ jobAlertErrors.salaryMax }}
                  </span>
                </div>
              </div>

              <!-- Location (City,State) for Job Alerts -->
              <div class="form-group" style="position: relative;">
                <label>Location <span v-if="jobAlertData.isEnabled" class="required">*</span></label>
                <input
                  v-model="alertLocationQuery"
                  type="text"
                  placeholder="Start typing a city name..."
                  class="input-field"
                  :class="{ 'error': jobAlertErrors.location }"
                  @input="filterAlertCitySuggestions"
                  @blur="handleAlertLocationBlur"
                />
                <!-- Suggestions dropdown -->
                <div v-if="showAlertSuggestions && alertFilteredCities.length" class="location-suggestions">
                  <div
                    v-for="(cityObj, idx) in alertFilteredCities"
                    :key="idx"
                    class="suggestion-item"
                    @mousedown.prevent="selectAlertCity(cityObj)"
                  >
                    {{ cityObj.cityName }},{{ cityObj.state }}
                  </div>
                </div>
                <span v-if="jobAlertErrors.location" class="error-text">
                  {{ jobAlertErrors.location }}
                </span>
                <!-- Show chosen city + state if selected -->
                <div v-if="alertSelectedCityObj" class="helper-text">
                  You selected:
                  <strong>{{ alertSelectedCityObj.cityName }},{{ alertSelectedCityObj.state }}</strong>
                </div>
              </div>

              <!-- Distance (Dropdown) -->
              <div class="form-group">
                <label>Distance (miles)</label>
                <div class="select-wrapper">
                  <select v-model.number="jobAlertData.distance" class="select-field">
                    <!-- Only allow 10,20,30,40 -->
                    <option v-for="m in [10,20,30,40]" :key="m" :value="m">
                      {{ m }}
                    </option>
                  </select>
                  <svg xmlns="http://www.w3.org/2000/svg" class="select-arrow" viewBox="0 0 24 24">
                    <path d="M7 10l5 5 5-5z" />
                  </svg>
                </div>
                <small class="helper-text">
                  Jobs within this radius of the chosen city/state
                </small>
              </div>

              <!-- Industries (optional) -->
              <div class="form-group">
                <label>Industries (optional)</label>
                <multiselect
                  v-model="jobAlertData.industries"
                  :options="['Technology','Healthcare','Finance','Retail','Other']"
                  multiple
                  placeholder="Select industries"
                />
              </div>

              <!-- Experience (optional) -->
              <div class="form-group">
                <label>Experience (optional)</label>
                <multiselect
                  v-model="jobAlertData.experiences"
                  :options="['Entry-Level','Mid-Level','Senior-Level']"
                  multiple
                  placeholder="Select experience"
                />
              </div>

              <!-- Job Type (optional) -->
              <div class="form-group">
                <label>Job Type (optional)</label>
                <multiselect
                  v-model="jobAlertData.jobTypes"
                  :options="['Full-Time','Part-Time','Contract','Internship']"
                  multiple
                  placeholder="Select job types"
                />
              </div>

              <!-- Save Job Alert button -->
              <div class="section-actions">
                <button
                  class="btn primary-btn"
                  :disabled="saving || !jobAlertData.isEnabled"
                  @click="saveJobAlert"
                >
                  <span v-if="!saving">Save Job Alert</span>
                  <span v-else class="loading-spinner"></span>
                </button>

                <span v-if="partialSaveStatus.jobAlerts" class="save-indicator">
                  <svg xmlns="http://www.w3.org/2000/svg" class="save-icon" viewBox="0 0 24 24">
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                  </svg>
                  Saved
                </span>
              </div>
            </section>

            <!-- Final step: Complete Profile -->
            <div class="navigation-actions">
              <button class="btn back-btn" @click="navigateToTab('compliance')">
                <svg xmlns="http://www.w3.org/2000/svg" class="nav-icon" viewBox="0 0 24 24">
                  <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                </svg>
                Back: Compliance
              </button>
              <button class="btn primary-btn" :disabled="saving" @click="submitAll">
                <span v-if="!saving">Complete Profile</span>
                <span v-else class="loading-spinner"></span>
              </button>
            </div>
          </div>
        </transition>

        <!-- Show error or success message globally -->
        <div v-if="errorMessage" class="error-message global-msg">{{ errorMessage }}</div>
        <div v-if="successMessage" class="success-message global-msg">{{ successMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { 
  onAuthStateChanged, 
  signOut
} from 'firebase/auth'
import { auth, db, storage } from '@/firebase'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

/** STEP DEFINITIONS */
const stepOrder = ['personal', 'compliance', 'jobAlerts']
const stepLabels = {
  personal: 'Personal Info',
  compliance: 'Compliance',
  jobAlerts: 'Job Alerts'
}

// Track current user
const currentUser = ref(null)

// Active tab
const activeTab = ref('personal')

// Basic messaging
const errorMessage = ref('')
const successMessage = ref('')

// Partial-saves
const partialSaveStatus = ref({
  basicInfo: false,
  resume: false,
  compliance: false,
  jobAlerts: false
})

// Validation errors (personal)
const validationErrors = ref({
  firstName: '',
  lastName: '',
  email: '',
  location: ''
})

// Job Alert errors
const jobAlertErrors = ref({
  title: '',
  salaryMin: '',
  salaryMax: '',
  location: ''
})

// Copy feedback for referral code
const codeCopied = ref(false)

// Loading state for saves
const saving = ref(false)

// Profile data object
const profileData = ref({
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  location: '',
  resumeUrl: '',
  referralCode: ''
})

// Compliance data object
const complianceData = ref({
  gender: '',
  race: '',
  veteranStatus: '',
  isDisabled: '',
  requiresSponsorship: '',
  isAuthorizedToWork: '',
  isAtLeast18: '',
  isAtLeast21: ''
})

// For resume upload
const resumeFile = ref(null)
const resumeFileName = ref('')

// City suggestions (for personal location)
const allCities = ref([])
const locationQuery = ref('')
const filteredCities = ref([])
const selectedCityObj = ref(null)
const showSuggestions = ref(false)

// Job alert location data
const alertLocationQuery = ref('')
const alertFilteredCities = ref([])
const alertSelectedCityObj = ref(null)
const showAlertSuggestions = ref(false)

// Job alert data
const jobAlertData = ref({
  isEnabled: false,
  title: '',
  salaryMin: 50000,
  salaryMax: 100000,
  city: '',
  state: '',
  distance: 30,
  industries: [],
  experiences: [],
  jobTypes: []
})

onMounted(() => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // If user is not verified, optionally sign them out or block them here.
      if (!user.emailVerified) {
        errorMessage.value = 'Your email is not verified. Please verify your email first.'
        signOut(auth)
        return
      }
      currentUser.value = user
      loadUserProfile(user.uid)
      loadComplianceData(user.uid)
      loadJobAlertData(user.uid)
    } else {
      currentUser.value = null
    }
  })
  fetchCityList()
})

// -------------------------------------------------------------------
// LOADING FUNCTIONS
// -------------------------------------------------------------------
async function loadUserProfile(uid) {
  try {
    const snap = await getDoc(doc(db, 'users', uid))
    if (snap.exists()) {
      const data = snap.data()
      profileData.value = {
        ...profileData.value,
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        email: data.email || '',
        phoneNumber: data.phoneNumber || '',
        location: data.location || '',
        resumeUrl: data.resumeUrl || '',
        referralCode: data.referralCode || ''
      }

      // If location was stored "City,State"
      if (profileData.value.location) {
        const [cName, st] = profileData.value.location.split(',')
        if (cName && st) {
          selectedCityObj.value = { cityName: cName, state: st.trim() }
          locationQuery.value = `${cName},${st}`
        }
      }
    }
  } catch (err) {
    console.error('Error loading user doc:', err)
    errorMessage.value = 'Error loading your profile.'
  }
}

async function loadComplianceData(uid) {
  try {
    const snap = await getDoc(doc(db, 'users', uid, 'compliance', 'complianceData'))
    if (snap.exists()) {
      const c = snap.data()
      complianceData.value = {
        gender: c.gender || '',
        race: c.race || '',
        veteranStatus: c.veteranStatus || '',
        isDisabled: c.isDisabled || '',
        requiresSponsorship: c.requiresSponsorship ? 'true' : 'false',
        isAuthorizedToWork: c.isAuthorizedToWork ? 'true' : 'false',
        isAtLeast18: c.isAtLeast18 ? 'true' : 'false',
        isAtLeast21: c.isAtLeast21 ? 'true' : 'false'
      }
    }
  } catch (err) {
    console.error('Error loading compliance doc:', err)
    errorMessage.value = 'Error loading compliance data.'
  }
}

async function loadJobAlertData(uid) {
  try {
    const alertSnap = await getDoc(doc(db, 'users', uid, 'alerts', 'dailyAlert'))
    if (alertSnap.exists()) {
      const data = alertSnap.data()
      // Merge existing defaults with loaded data
      jobAlertData.value = { ...jobAlertData.value, ...data }

      // If city & state exist, set them into the local "alertSelectedCityObj"
      if (data.city && data.state) {
        alertSelectedCityObj.value = { cityName: data.city, state: data.state }
        alertLocationQuery.value = `${data.city},${data.state}`
      }
    }
  } catch (err) {
    console.error('Failed to load job alert:', err)
  }
}

// Fetch city list from your .txt file (for autocompletes)
async function fetchCityList() {
  try {
    const response = await fetch('/us_cities_with_coordinates.txt')
    const rawText = await response.text()
    const lines = rawText.split('\n').map(line => line.trim()).filter(Boolean)
    const parsed = lines.map(line => {
      const parts = line.split(',')
      return {
        cityName: parts[0].replace(/(^'|'$)/g, ''),
        state: parts[1]?.trim(),
        lat: parts[2] ? parseFloat(parts[2]) : null,
        lng: parts[3] ? parseFloat(parts[3]) : null
      }
    })
    allCities.value = parsed
  } catch (err) {
    console.error('Failed to load city list:', err)
  }
}

// -------------------------------------------------------------------
// SIGN OUT
// -------------------------------------------------------------------
function handleSignOut() {
  signOut(auth)
    .then(() => {
      console.log('User signed out successfully')
      // Optionally navigate or show success message
    })
    .catch((error) => {
      console.error('Error signing out:', error)
      errorMessage.value = 'Error signing out. Please try again.'
    })
}

// -------------------------------------------------------------------
// LOCATION HELPERS (Personal Info)
// -------------------------------------------------------------------
function filterCitySuggestions() {
  showSuggestions.value = true
  selectedCityObj.value = null
  validationErrors.value.location = ''

  if (!locationQuery.value) {
    filteredCities.value = []
    return
  }
  const q = locationQuery.value.toLowerCase()
  filteredCities.value = allCities.value
    .filter(cityObj => {
      const combo = `${cityObj.cityName},${cityObj.state}`.toLowerCase()
      return combo.includes(q)
    })
    .slice(0, 15)
}

function selectCity(cityObj) {
  selectedCityObj.value = cityObj
  locationQuery.value = `${cityObj.cityName},${cityObj.state}`
  filteredCities.value = []
  showSuggestions.value = false
}

function handleLocationBlur() {
  showSuggestions.value = false
  if (!selectedCityObj.value) {
    const match = allCities.value.find(
      c => `${c.cityName},${c.state}`.toLowerCase() === locationQuery.value.toLowerCase()
    )
    if (match) {
      selectedCityObj.value = match
    } else {
      validationErrors.value.location = 'Please select a valid city from the list.'
    }
  }
}

function finalizePersonalLocation() {
  if (!selectedCityObj.value) {
    const match = allCities.value.find(
      c => `${c.cityName},${c.state}`.toLowerCase() === locationQuery.value.toLowerCase()
    )
    if (match) {
      selectedCityObj.value = match
    }
  }
}

// -------------------------------------------------------------------
// LOCATION HELPERS (Job Alerts)
// -------------------------------------------------------------------
function filterAlertCitySuggestions() {
  showAlertSuggestions.value = true
  alertSelectedCityObj.value = null
  jobAlertErrors.value.location = ''

  if (!alertLocationQuery.value) {
    alertFilteredCities.value = []
    return
  }
  const q = alertLocationQuery.value.toLowerCase()
  alertFilteredCities.value = allCities.value
    .filter(cityObj => {
      const combo = `${cityObj.cityName},${cityObj.state}`.toLowerCase()
      return combo.includes(q)
    })
    .slice(0, 15)
}

function selectAlertCity(cityObj) {
  alertSelectedCityObj.value = cityObj
  alertLocationQuery.value = `${cityObj.cityName},${cityObj.state}`
  alertFilteredCities.value = []
  showAlertSuggestions.value = false
}

function handleAlertLocationBlur() {
  showAlertSuggestions.value = false
  if (!alertSelectedCityObj.value) {
    const match = allCities.value.find(
      c => `${c.cityName},${c.state}`.toLowerCase() === alertLocationQuery.value.toLowerCase()
    )
    if (match) {
      alertSelectedCityObj.value = match
    } else {
      jobAlertErrors.value.location = 'Please select a valid city.'
    }
  }
}

function finalizeAlertLocation() {
  if (!alertSelectedCityObj.value) {
    const match = allCities.value.find(
      c => `${c.cityName},${c.state}`.toLowerCase() === alertLocationQuery.value.toLowerCase()
    )
    if (match) {
      alertSelectedCityObj.value = match
    }
  }
}

// -------------------------------------------------------------------
// SAVE PERSONAL INFO
// -------------------------------------------------------------------
function validatePersonal() {
  finalizePersonalLocation()

  validationErrors.value.firstName = profileData.value.firstName ? '' : 'First name is required.'
  validationErrors.value.lastName = profileData.value.lastName ? '' : 'Last name is required.'
  validationErrors.value.email = profileData.value.email ? '' : 'Email is required.'

  if (!selectedCityObj.value) {
    validationErrors.value.location = 'Please select a valid city.'
  } else {
    validationErrors.value.location = ''
  }

  return (
    !validationErrors.value.firstName &&
    !validationErrors.value.lastName &&
    !validationErrors.value.email &&
    !validationErrors.value.location
  )
}

async function saveBasicInfo() {
  if (!currentUser.value) {
    errorMessage.value = 'You must be logged in.'
    return
  }
  if (!validatePersonal()) return

  try {
    saving.value = true
    errorMessage.value = ''
    successMessage.value = ''

    let locationString = ''
    if (selectedCityObj.value) {
      locationString = `${selectedCityObj.value.cityName},${selectedCityObj.value.state}`
    }

    await setDoc(doc(db, 'users', currentUser.value.uid), {
      firstName: profileData.value.firstName,
      lastName: profileData.value.lastName,
      email: profileData.value.email,
      phoneNumber: profileData.value.phoneNumber,
      location: locationString
    }, { merge: true })

    profileData.value.location = locationString
    partialSaveStatus.value.basicInfo = true
    successMessage.value = 'Basic info saved!'
  } catch (err) {
    console.error(err)
    errorMessage.value = 'Error saving Basic Info.'
  } finally {
    saving.value = false
  }
}

// -------------------------------------------------------------------
// RESUME HANDLING
// -------------------------------------------------------------------
function handleResumeFile(e) {
  const file = e.target.files[0]
  if (file) {
    resumeFile.value = file
    resumeFileName.value = file.name
  }
}

function clearResumeFile() {
  resumeFile.value = null
  resumeFileName.value = ''
}

function getResumeFileName() {
  if (!profileData.value.resumeUrl) return ''
  try {
    const parts = profileData.value.resumeUrl.split('/')
    return parts[parts.length - 1].split('?')[0]
  } catch {
    return 'Resume.pdf'
  }
}

async function saveResume() {
  if (!currentUser.value) {
    errorMessage.value = 'You must be logged in.'
    return
  }
  if (!resumeFile.value) {
    errorMessage.value = 'Please select a resume file first.'
    return
  }
  try {
    saving.value = true
    errorMessage.value = ''
    successMessage.value = ''

    const sRef = storageRef(storage, `resumes/${currentUser.value.uid}/${resumeFile.value.name}`)
    await uploadBytes(sRef, resumeFile.value)
    const newResumeUrl = await getDownloadURL(sRef)

    await setDoc(doc(db, 'users', currentUser.value.uid), {
      resumeUrl: newResumeUrl
    }, { merge: true })

    profileData.value.resumeUrl = newResumeUrl
    resumeFile.value = null
    resumeFileName.value = ''
    partialSaveStatus.value.resume = true
    successMessage.value = 'Resume uploaded & saved!'
  } catch (err) {
    console.error(err)
    errorMessage.value = 'Error saving resume.'
  } finally {
    saving.value = false
  }
}

// -------------------------------------------------------------------
// REFERRAL CODE
// -------------------------------------------------------------------
function copyReferralCode() {
  if (!profileData.value.referralCode) return
  navigator.clipboard.writeText(profileData.value.referralCode)
    .then(() => {
      codeCopied.value = true
    })
    .catch(() => {
      errorMessage.value = 'Failed to copy referral code.'
    })
}

// -------------------------------------------------------------------
// SAVE COMPLIANCE
// -------------------------------------------------------------------
async function saveCompliance() {
  if (!currentUser.value) {
    errorMessage.value = 'You must be logged in.'
    return
  }
  try {
    saving.value = true
    errorMessage.value = ''
    successMessage.value = ''

    await setDoc(
      doc(db, 'users', currentUser.value.uid, 'compliance', 'complianceData'),
      {
        gender: complianceData.value.gender,
        race: complianceData.value.race,
        veteranStatus: complianceData.value.veteranStatus,
        isDisabled: complianceData.value.isDisabled,
        requiresSponsorship: complianceData.value.requiresSponsorship === 'true',
        isAuthorizedToWork: complianceData.value.isAuthorizedToWork === 'true',
        isAtLeast18: complianceData.value.isAtLeast18 === 'true',
        isAtLeast21: complianceData.value.isAtLeast21 === 'true'
      },
      { merge: true }
    )

    partialSaveStatus.value.compliance = true
    successMessage.value = 'Compliance data saved!'
  } catch (err) {
    console.error(err)
    errorMessage.value = 'Error saving compliance data.'
  } finally {
    saving.value = false
  }
}

// -------------------------------------------------------------------
// JOB ALERTS LOGIC
// -------------------------------------------------------------------
function validateJobAlert() {
  finalizeAlertLocation()

  jobAlertErrors.value = {
    title: '',
    salaryMin: '',
    salaryMax: '',
    location: ''
  }

  if (!jobAlertData.value.isEnabled) {
    return true // no required fields if disabled
  }
  if (!jobAlertData.value.title.trim()) {
    jobAlertErrors.value.title = 'Job title is required.'
  }
  if (!jobAlertData.value.salaryMin) {
    jobAlertErrors.value.salaryMin = 'Min salary is required.'
  }
  if (!jobAlertData.value.salaryMax) {
    jobAlertErrors.value.salaryMax = 'Max salary is required.'
  }
  if (!alertSelectedCityObj.value) {
    jobAlertErrors.value.location = 'Please select a valid city.'
  }

  return !Object.values(jobAlertErrors.value).some(e => e !== '')
}

async function saveJobAlert() {
  if (!currentUser.value) {
    errorMessage.value = 'You must be logged in.'
    return
  }
  if (!validateJobAlert()) {
    return
  }
  try {
    saving.value = true
    errorMessage.value = ''
    successMessage.value = ''

    if (jobAlertData.value.isEnabled && alertSelectedCityObj.value) {
      jobAlertData.value.city = alertSelectedCityObj.value.cityName
      jobAlertData.value.state = alertSelectedCityObj.value.state
    }

    await setDoc(
      doc(db, 'users', currentUser.value.uid, 'alerts', 'dailyAlert'),
      jobAlertData.value,
      { merge: true }
    )

    partialSaveStatus.value.jobAlerts = true
    successMessage.value = 'Job alert saved!'
  } catch (err) {
    console.error('Error saving job alert:', err)
    errorMessage.value = 'Failed to save job alert.'
  } finally {
    saving.value = false
  }
}

// -------------------------------------------------------------------
// FINAL SUBMISSION
// -------------------------------------------------------------------
async function submitAll() {
  if (!currentUser.value) {
    errorMessage.value = 'You must be logged in.'
    return
  }
  // Validate personal info first
  if (!validatePersonal()) {
    activeTab.value = 'personal'
    return
  }
  // Validate job alerts if enabled
  if (!validateJobAlert()) {
    activeTab.value = 'jobAlerts'
    return
  }

  try {
    saving.value = true
    errorMessage.value = ''
    successMessage.value = ''

    // Possibly re-upload resume if new file is selected
    let newResumeUrl = profileData.value.resumeUrl
    if (resumeFile.value) {
      const sRef = storageRef(storage, `resumes/${currentUser.value.uid}/${resumeFile.value.name}`)
      await uploadBytes(sRef, resumeFile.value)
      newResumeUrl = await getDownloadURL(sRef)
    }

    let locationString = ''
    if (selectedCityObj.value) {
      locationString = `${selectedCityObj.value.cityName},${selectedCityObj.value.state}`
    }

    // Save main doc (personal info)
    await setDoc(
      doc(db, 'users', currentUser.value.uid),
      {
        firstName: profileData.value.firstName,
        lastName: profileData.value.lastName,
        email: profileData.value.email,
        phoneNumber: profileData.value.phoneNumber,
        location: locationString,
        resumeUrl: newResumeUrl
      },
      { merge: true }
    )

    // Save compliance subdoc
    await setDoc(
      doc(db, 'users', currentUser.value.uid, 'compliance', 'complianceData'),
      {
        gender: complianceData.value.gender,
        race: complianceData.value.race,
        veteranStatus: complianceData.value.veteranStatus,
        isDisabled: complianceData.value.isDisabled,
        requiresSponsorship: complianceData.value.requiresSponsorship === 'true',
        isAuthorizedToWork: complianceData.value.isAuthorizedToWork === 'true',
        isAtLeast18: complianceData.value.isAtLeast18 === 'true',
        isAtLeast21: complianceData.value.isAtLeast21 === 'true'
      },
      { merge: true }
    )

    // Save job alert subdoc
    if (jobAlertData.value.isEnabled && alertSelectedCityObj.value) {
      jobAlertData.value.city = alertSelectedCityObj.value.cityName
      jobAlertData.value.state = alertSelectedCityObj.value.state
    }
    await setDoc(
      doc(db, 'users', currentUser.value.uid, 'alerts', 'dailyAlert'),
      jobAlertData.value,
      { merge: true }
    )

    // Mark partial steps as saved
    profileData.value.location = locationString
    profileData.value.resumeUrl = newResumeUrl
    resumeFile.value = null
    resumeFileName.value = ''
    codeCopied.value = false

    partialSaveStatus.value.basicInfo = true
    partialSaveStatus.value.resume = true
    partialSaveStatus.value.compliance = true
    partialSaveStatus.value.jobAlerts = true

    successMessage.value = 'Profile completed successfully!'
  } catch (err) {
    console.error(err)
    errorMessage.value = 'Error saving profile. Please try again.'
  } finally {
    saving.value = false
  }
}

// -------------------------------------------------------------------
// STEP NAVIGATION
// -------------------------------------------------------------------
function setActiveTab(tab) {
  errorMessage.value = ''
  successMessage.value = ''
  codeCopied.value = false
  activeTab.value = tab
}

function navigateToTab(tab) {
  // For personal tab:
  if (activeTab.value === 'personal' && !validatePersonal()) return
  errorMessage.value = ''
  successMessage.value = ''
  codeCopied.value = false
  activeTab.value = tab
}

function isStepCompleted(step) {
  if (step === 'personal') {
    return partialSaveStatus.value.basicInfo
  } else if (step === 'compliance') {
    return partialSaveStatus.value.compliance
  } else if (step === 'jobAlerts') {
    return partialSaveStatus.value.jobAlerts
  }
  return false
}
</script>

<style scoped>
/* Container as a white "card" like the login page, with a subtle shadow */
.edit-profile-container {
  max-width: 1200px; 
  margin: 2rem auto;
  padding: 0 1rem;
}

/* Title row similar to login's big orange title */
.header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.page-title {
  font-size: 1.8rem;
  font-weight: 700;
  color: #fc7115; /* same brand color as login */
  margin: 0;
}

/* Stepper */
.progress-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.stepper {
  display: flex;
  gap: 0.5rem;
}
.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 0.3rem 0.5rem;
}
.step-indicator {
  width: 32px;
  height: 32px;
  background: #eee;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.3rem;
}
.step-label {
  font-size: 0.85rem;
  color: #666;
  text-align: center;
  white-space: nowrap;
}
.step.completed .step-indicator {
  background: #28a745;
  color: #fff;
}
.step.active .step-indicator {
  background: #fc7115;
  color: #fff;
}

/* If not logged in */
.not-logged-in {
  text-align: center;
  margin-top: 2rem;
}
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}
.empty-icon {
  width: 40px;
  height: 40px;
  fill: #ccc;
}

/* Main layout */
.main-layout {
  display: flex;
  gap: 1.5rem;
}

/* Sidebar with subtle shadow around items */
.sidebar {
  flex: 0 0 240px;
  border-right: 1px solid #eee;
  padding-right: 1rem;
}
.sidebar-nav {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.nav-item {
  background: #fafafa;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 0.6rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #333;
  position: relative;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.2s;
}
.nav-item:hover {
  background: #f0f0f0;
}
.nav-item.active {
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
}
.icon {
  width: 20px;
  height: 20px;
  fill: currentColor;
}
.completion-indicator {
  position: absolute;
  right: 8px;
  font-weight: bold;
  color: #28a745;
}

/* Sign out button uses a red-ish background, still keep the brand style if you prefer */
.signout-btn {
  background-color: #dc3545;
  color: #fff;
}
.signout-btn:hover {
  background-color: #c82333;
}

/* Content area */
.content-area {
  flex: 1;
  min-height: 600px;
}
.mobile-tabs {
  display: none;
  margin-bottom: 1rem;
}
.mobile-tab-selector {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
}
@media (max-width: 768px) {
  .main-layout {
    flex-direction: column;
  }
  .sidebar {
    display: none;
  }
  .mobile-tabs {
    display: block;
  }
}

/* Tab panels */
.tab-panel {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Cards that match login page style: white background, subtle shadow, round corners */
.card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem 1.25rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
}
.section-title {
  margin-top: 0;
  font-size: 1.3rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 0.25rem;
}
.section-description {
  font-size: 0.95rem;
  color: #666;
  margin-bottom: 1rem;
}
.form-divider {
  width: 100%;
  height: 1px;
  background: #eee;
  margin-bottom: 1rem;
}

/* 2-column grid */
.two-column-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
@media (max-width: 768px) {
  .two-column-grid {
    grid-template-columns: 1fr;
  }
}

/* Form groups */
.form-group {
  display: flex;
  flex-direction: column;
}
.form-group label {
  font-weight: 600;
  margin-bottom: 0.25rem;
  color: #333;
}
.required {
  color: #dc3545;
  font-weight: 700;
}

/* Inputs: match the login style more closely */
.input-field {
  padding: 0.6rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
  transition: border-color 0.2s;
}
.input-field.error {
  border-color: red;
}
.error-text {
  color: red;
  font-size: 0.85rem;
  margin-top: 0.25rem;
}
.helper-text {
  font-size: 0.75rem;
  color: #999;
  margin-top: 0.25rem;
}

/* Input with icon */
.input-with-icon {
  position: relative;
}
.field-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  fill: #aaa;
}

/* Suggestions dropdown for city */
.location-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  border: 1px solid #ccc;
  z-index: 999;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.08);
}
.suggestion-item {
  padding: 0.5rem;
  cursor: pointer;
}
.suggestion-item:hover {
  background-color: #f7f7f7;
}

/* File upload */
.hidden-file-input {
  display: none;
}
.file-upload-label {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  background: #f0f0f0;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  border: 1px solid #ddd;
  transition: background 0.2s;
}
.file-upload-label:hover {
  background: #e2e2e2;
}
.upload-icon {
  width: 20px;
  height: 20px;
  fill: #666;
}
.selected-file {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  gap: 0.5rem;
}
.file-icon {
  width: 20px;
  height: 20px;
  fill: #333;
}

/* Buttons: match the same orange gradient from the login */
.btn {
  border: none;
  cursor: pointer;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.95rem;
  padding: 0.6rem 1rem;
  border-radius: 4px;
  color: #fff;
  background-color: #999;
  transition: background-image 0.2s ease;
}
.btn:hover {
  filter: brightness(0.95);
}
.btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  color: #666;
}
/* Primary button with gradient */
.primary-btn {
  background-color: #fc7115;
  background-image: linear-gradient(to bottom right, #fc7115, #ff9040);
}
.primary-btn:hover {
  background-image: linear-gradient(to bottom right, #e35d0a, #f9822f);
}
/* Gray back button */
.back-btn {
  background-color: #6c757d;
  background-image: none;
}
.back-btn:hover {
  background-color: #5a6268;
}
/* Blue next button */
.next-btn {
  background-color: #007bff;
  background-image: none;
}
.next-btn:hover {
  background-color: #0056b3;
}
.text-btn {
  background: none;
  color: #555;
  padding: 4px;
}
.icon-btn {
  background-color: #eee;
  color: #333;
  border: 1px solid #ddd;
  padding: 0.4rem;
  transition: background 0.2s;
}
.icon-btn:hover {
  background-color: #ddd;
}

/* Section actions */
.section-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}
.save-indicator {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  color: #28a745;
}
.save-icon {
  width: 18px;
  height: 18px;
  fill: currentColor;
}

/* Navigation actions */
.navigation-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 1rem;
}
.nav-icon {
  width: 18px;
  height: 18px;
  fill: currentColor;
  margin-left: 0.25rem;
}

/* Resume section */
.resume-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.document-display {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}
.document-icon {
  width: 32px;
  height: 32px;
  fill: #666;
}
.document-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.document-link {
  color: #007bff;
  text-decoration: underline;
}
.document-empty {
  color: #999;
}

/* Referral */
.referral-container {
  margin-top: 1rem;
}
.referral-row {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.referral-field {
  flex: 1;
  padding: 0.6rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.copy-confirmation {
  color: green;
  font-size: 0.85rem;
  margin-top: 0.5rem;
}

/* Selects (for compliance, etc.) */
.select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}
.select-field {
  width: 100%;
  appearance: none;
  padding: 0.6rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
  background-color: #fff;
  transition: border-color 0.2s;
}
.select-arrow {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  fill: #aaa;
  width: 16px;
  height: 16px;
}

/* Info tooltip */
.info-tooltip {
  display: inline-block;
  position: relative;
  margin-left: 0.5rem;
  cursor: help;
}
.info-icon {
  width: 18px;
  height: 18px;
  fill: #aaa;
}
.tooltip-text {
  visibility: hidden;
  width: 250px;
  background-color: #333;
  color: #fff;
  text-align: left;
  padding: 0.5rem;
  border-radius: 4px;
  position: absolute;
  z-index: 99;
  top: 120%;
  left: 0;
  font-size: 0.75rem;
}
.info-tooltip:hover .tooltip-text {
  visibility: visible;
}

/* Error / Success messages that appear globally */
.global-msg {
  margin-top: 1rem;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.error-message.global-msg {
  color: red;
}
.success-message.global-msg {
  color: green;
}

/* Fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Loading spinner (same as login) */
.loading-spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
  margin-left: 8px;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
